import { apiFetch } from '../apihelper';  // Import the apiFetch helper

const registerUser = async (data) => {
    try {
        const response = await apiFetch('auth/register', {  // Use relative path, base URL is handled by apiFetch
            method: 'POST',
            body: JSON.stringify(data),
        });

        // Return the success message or any additional info
        return response;  // apiFetch already parses the response
    } catch (error) {
        console.error('Registration failed:', error.message); 
        throw error;  // Rethrow the error to handle it at a higher level if needed
    }
};

export { registerUser };
