import { apiFetch } from '../apihelper';  // Import the apiFetch helper

export const loginUser = async (email, password) => {
    const response = await apiFetch('auth/login', {  // Use relative path, base URL is handled by apiFetch
        method: 'POST',
        body: JSON.stringify({ email, password }),
    });

    // Store the token in session storage
    sessionStorage.setItem('token', response.token);

    // Return the success message or any additional info
    return { message: 'Login successful', ...response };
};
