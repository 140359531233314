import '../styles/categoryskeleton.css'

const CategorySkeleton = () => {
    return (
        <div className='bg-[#F8F7F7] h-auto p-5 flex flex-col sm:flex-row items-center justify-between rounded-[34px] md:rounded-[84px]'>
            <div className='block w-full sm:w-auto'>
                <div className='bg-[#D9D9D980] mb-2 bg-opacity-5 h-[17px] rounded-[20px] w-full  skeleton'>
                </div>
                <div className='bg-[#D9D9D980] bg-opacity-5 h-[14px] rounded-[20px] w-full sm:w-[6rem] skeleton'>
                </div>
            </div>
            <div className='flex flex-col sm:flex-row items-center justify-between sm:justify-end w-full sm:w-auto mt-3 sm:mt-0'>
                <div className='bg-[#D9D9D980] h-[22px] w-[46px] mr-2 rounded-[20px] skeleton'>
                </div>
                <div className='bg-[#D9D9D980] h-[27px] w-[81px] rounded-[6px] skeleton'>
                </div>
            </div>
        </div>
    )
}

export default CategorySkeleton;
