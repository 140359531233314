import shopifybag from '../../../../assets/shopifybag.png'
import React, { useState, useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css' // Import Toastify styles
import {
  postDataToStore,
  getshopifylist,
  insertdataset,
  trainDataset,
  authShopify,
  UpdateMetaStore,
  getStorebyID
} from '../../../../services/data/index'
import CategorySkeleton from '../../../../components/cateogryskeleton'
import { useNavigate } from 'react-router-dom'

const ListData = () => {
  const hasRun = useRef(false) // Ref to keep track if the effect has already run
  const [url, setUrl] = useState('')
  const [isTrimmed, setIsTrimmed] = useState(false)
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState()
  const [timeoutId, setTimeoutId] = useState(null)
  const [showcategory, setShowCategory] = useState(false)
  const [listcategory, setListCategory] = useState([]) // Initialize as an empty array
  const [showDialog, setShowDialog] = useState(false) // State for dialog visibility
  const [showitemdataset, setshowitemdataset] = useState(false)
  const [dataset, setdataset] = useState([])
  const [sessionMeta, setsessionMeta] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [showbotloader, setbotloader] = useState(false)
  const navigate = useNavigate()
  const [storeCreate, setstoreCreate] = useState(false)
  const [success, setsuccess] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [FilteredData, setFilteredData] = useState('')

  const [shopifySession, setShopifySession] = useState(null)

  // useEffect(() => {
  //   console.log(listcategory)
  // })

  const toggleStatus = async index => {
    const updatedCategory = listcategory[index]
    const newStatus =
      updatedCategory.status === 'active' ? 'inactive' : 'active'

    // Update the status of child items based on the new parent status
    const updatedData = updatedCategory.data.map(child => ({
      ...child,
      status: newStatus === 'active' ? 'active' : 'inactive'
    }))

    // Calculate activeCount and totalCount
    const activeCount = updatedData.filter(
      child => child.status === 'active'
    ).length
    const totalCount = updatedData.length

    // Update selectedCategory
    setSelectedCategory({
      ...updatedCategory,
      status: newStatus,
      data: updatedData,
      activeCount,
      totalCount
    })

    // Update the listcategory
    setListCategory(prevList =>
      prevList.map((item, idx) =>
        idx === index
          ? {
              ...item,
              status: newStatus,
              data: updatedData,
              activeCount,
              totalCount
            }
          : item
      )
    )
  }

  // Update FilteredData whenever selectedCategory or searchQuery changes
useEffect(() => {
  setFilteredData(
    selectedCategory?.data?.filter(item =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []
  );
}, [selectedCategory, searchQuery]);

// Handle status toggling correctly using the original index
const childtoggleStatus = filteredIndex => {
  // Get the actual index in selectedCategory.data
  const originalIndex = selectedCategory.data.findIndex(
    item => item.title === FilteredData[filteredIndex].title
  );

  if (originalIndex === -1) return; // If not found, exit

  const updatedCategory = { ...selectedCategory };
  const updatedData = [...updatedCategory.data];

  // Check if the item is not disabled
  if (!updatedData[originalIndex].disabled) {
    // Toggle the child's status
    updatedData[originalIndex] = {
      ...updatedData[originalIndex],
      status: updatedData[originalIndex].status === 'inactive' ? 'active' : 'inactive',
    };

    // Count the active children
    const activeCount = updatedData.filter(child => child.status === 'active').length;
    const totalCount = updatedData.length;

    // Determine parent status
    const hasActiveChild = activeCount > 0;

    // Update the selected category with counts
    setSelectedCategory({
      ...updatedCategory,
      status: hasActiveChild ? 'active' : 'inactive',
      data: updatedData,
      activeCount, // Add active count
      totalCount, // Add total count
    });

    // Update the corresponding category in the listcategory array
    setListCategory(prevList =>
      prevList.map(item => {
        if (item.name === updatedCategory.name) {
          return {
            ...item,
            status: hasActiveChild ? 'active' : 'inactive',
            data: updatedData,
            activeCount, // Add active count
            totalCount, // Add total count
          };
        }
        return item;
      })
    );
  } else {
    console.log(`Item at index ${originalIndex} is disabled and cannot be toggled.`);
  }
};

  useEffect(() => {
    // const activeCategories = listcategory.filter(item => item.status === 'active').map(item => ({
    //     categoryName: item.name,
    //     data: item.data // Keep only active categories' data
    // }));
    const activeCategories = listcategory.map(item => ({
      categoryName: item.name,
      data: item.data // Keep only active categories' data
    }))
    setdataset(activeCategories)
  }, [listcategory]) // Only runs when listcategory changes

  const handlepassdata = () => {
    localStorage.removeItem('shopifySession')

    console.log(dataset)

    // Check if dataset is an array
    if (Array.isArray(dataset)) {
      // Create an array to store the updated dataset
      const updatedDataset = dataset.map(i => {
        // For each category, map through its data (i.e., i.data)
        const items_data = i.data

        // console.log(items_data)

        // Modify the status of each item in the data
        const updatedItemsData = items_data.map(j => {
          // Toggle the status from 'inactive' to 'active' and vice versa
          // console.log(j.status)
          return {
            ...j,
            status: j.status === 'active' ? 'active' : 'inactive'
          }
        })

        // Return the updated category with updated items data
        return { ...i, data: updatedItemsData }
      })

      // Flatten the updated dataset to get all items in a single array of data
      const dataToSend = updatedDataset.flatMap(i => i.data)

      // Update the dataset state
      setdataset(updatedDataset)
      // console.log(dataToSend);

      // Now, pass the `dataToSend` array to the API
      sendDataToAPI(dataToSend)
    }
  }

  // Function to send data to the API
  const sendDataToAPI = async dataToSend => {
    try {
      // Filter out inactive datasets from the data before sending to API
      const activeDataToSend = dataToSend.filter(
        item => item.status === 'active'
      )

      // If no active datasets, show a message and return early
      if (activeDataToSend.length === 0) {
        navigate('/training')
        toast.info('No active datasets to insert.', {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        return // Exit the function early
      }

      // Proceed with inserting only active datasets
      const getdataset = await insertdataset(activeDataToSend) // Insert active datasets and wait for the response

      if (getdataset.success && Array.isArray(getdataset.data)) {
        // Extract all dataset IDs that are active
        const allIds = getdataset.data
          .filter(item => item.status === 'active')
          .map(item => item.dataset_id) // Extract dataset IDs

        if (allIds.length > 0) {
          // Prepare the payload with all IDs and a fixed status of "active"
          const payload = {
            status: 'active',
            ids: allIds
          }

          // Pass the payload to trainDataset function
          const trainDatasetResponse = await trainDataset(payload) // Train dataset with the payload

          if (trainDatasetResponse.success) {
            toast.success('Dataset trained successfully!', {
              position: 'bottom-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            })

            window.dispatchEvent(
              new CustomEvent('listmodalclose', { detail: true })
            )

            // Navigate to training page
            navigate('/training')
            // Dispatch success events
            window.dispatchEvent(
              new CustomEvent('checkStoreId', { detail: true })
            )
            window.dispatchEvent(
              new CustomEvent('datatarined', { detail: true })
            )
          }
        } else {
          toast.info('No datasets found to train.', {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
          setbotloader(false)
        }
      } else {
        setbotloader(false)
        throw new Error('Unexpected API response or data structure.')
      }
    } catch (error) {
      console.error('Error sending data:', error) // Log any errors
      toast.error('Failed to load data', {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
      setbotloader(false)
    }
  }

  const viewpages = index => {
    const Category = listcategory[index]
    setSelectedCategory(Category)
    setshowitemdataset(true)
    // console.log(selectedCategory.data)
  }

  const handleinstall = async error => {
    // console.error('Error training items:', error)
    // toast.error(error.message, {
    //   position: 'bottom-center'
    // })

    const message = error.message

    const hasKeywords =
      message.toLowerCase().includes('authentication') &&
      message.toLowerCase().includes('shopify')

    if (hasKeywords) {
      const store_id = sessionStorage.getItem('store_id')
      const store = await getStorebyID(store_id)
      const baseURL = store.data.name
      const fullURL = `${baseURL}.myshopify.com`
      const shopifysessionresync = await authShopify(
        fullURL.replace('https://', '').replace(/\/$/, '')
      )
      window.open(shopifysessionresync, '_blank', 'width=600,height=600')

      const data = {
        name: store.data.name,
        type: 'shopify',
        user_id: JSON.parse(sessionStorage.getItem('user')).id,
        meta: JSON.parse(localStorage.getItem('shopifySession'))
      }

      const res = await UpdateMetaStore(data, store_id) // Await the async post data request
      console.log(res)
      if (res.success) {
        GetUrlData()
        localStorage.removeItem('shopifySession')
      } else {
        console.log('no')
      }

      // Output: https://steppingedge-theme-prototype-1.myshopify.com
      // const shopify = new Shopify(store.shop, store.access_token)
      // const res = await shopify.getProducts()
      // const store_id = sessionStorage.getItem('store_id')
      // const store = await getStorebyID(store_id)
      // console.log(store)
    }
  }

  const GetUrlData = async () => {
    setLoading(true)
    try {
      const shopifyurldata = await getshopifylist(
        sessionStorage.getItem('store_id')
      )
      setCategories(shopifyurldata)
      setShowCategory(true)
      setsuccess(true)
    } catch (error) {
      // toast.error('Failed to load data', {
      //   position: 'bottom-center',
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true
      // })
      handleinstall(error)
    }
  }

  useEffect(() => {
    if (success) {
      toast.success('Data loaded successfully!', {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    }
  }, [success])

  useEffect(() => {
    if (showDialog) {
      setLoading(true)
    }
    if (loading) {
      const id = setTimeout(() => {
        setLoading(false)
      }, 1000)
      setTimeoutId(id)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [loading])

  useEffect(() => {
    // Update listcategory whenever categories change
    if (categories) {
      setListCategory([
        {
          name: 'Products',
          data: categories.products || [],
          status: 'inactive',

          activeCount: 0,
          totalCount: 0
        },
        {
          name: 'Collections',
          data: categories.collections || [],
          status: 'inactive',
          activeCount: 0,
          totalCount: 0
        },
        {
          name: 'Articles',
          data: categories.articles || [],
          status: 'inactive',
          activeCount: 0,
          totalCount: 0
        },
        {
          name: 'Pages',
          data: categories.pages || [],
          status: 'inactive',
          activeCount: 0,
          totalCount: 0
        }
      ])
    }
  }, [categories])

  useEffect(() => {
    GetUrlData()
  }, [])

  const handleBackClick = () => {
    if (loading) {
      setShowDialog(true)
    } else {
      // handleback();
    }
  }

  const handlecancel = () => {
    // handleback();
  }

  const closeDialog = () => {
    setShowDialog(false) // Hide the dialog
    setshowitemdataset(false)
  }


  return (
    <div>
      {showitemdataset && (
        <div className='fixed inset-0 flex bg-black bg-opacity-50 z-[90] p-5'>
          <div
            className='bg-[#FFFFFF] px-5 pb-3 shadow-lg w-full max-w-[700px] mx-auto my-[30px]   rounded-[24px] border-[1.6px] border-white overflow-y-auto  max-h-[80%] scroll-m-16 scrollbar-custom1'
            style={{ boxShadow: '-1px 0px 0px 0px #DFE5F1 inset' }}
          >
            <div className='block'>
              {/* Close Button */}
              <div className='flex bg-white justify-end sticky top-0 px-2 py-3 z-50'>
                <button
                  onClick={closeDialog}
                  className='w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] text-[14px] sm:text-[18px] cursor-pointer'
                >
                  <svg
                    width='30'
                    height='30'
                    viewBox='0 0 30 30'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M9.10041 7.77459C8.7343 7.40847 8.1407 7.40847 7.77459 7.77459C7.40847 8.1407 7.40847 8.7343 7.77459 9.10041L13.6742 15L7.77459 20.8996C7.40847 21.2657 7.40847 21.8593 7.77459 22.2254C8.1407 22.5915 8.7343 22.5915 9.10041 22.2254L15 16.3258L20.8996 22.2254C21.2657 22.5915 21.8593 22.5915 22.2254 22.2254C22.5915 21.8593 22.5915 21.2657 22.2254 20.8996L16.3258 15L22.2254 9.10041C22.5915 8.7343 22.5915 8.1407 22.2254 7.77459C21.8593 7.40847 21.2657 7.40847 20.8996 7.77459L15 13.6742L9.10041 7.77459Z'
                      fill='#373737'
                    />
                  </svg>
                </button>
              </div>

              {/* Search Input */}
              <div className='mt-3'>
                <input
                  type='text'
                  placeholder='Search items...'
                  className='outline-none w-full p-2 rounded-[24px] border border-gray-300 mb-4'
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
              </div>

              {/* Item List */}
              <div>
                {Array.isArray(FilteredData) && FilteredData.length > 0 ? (
                  FilteredData.map((item, index) => (
                    <div
                      key={index}
                      className='w-full p-4 sm:p-5 h-[54px] mt-2 flex justify-between items-center rounded-[84px] border-[0.5px] bg-[#F8F7F7]'
                    >
                      <h2 className='text-sm sm:text-base'>{item.title}</h2>
                      <div
                        onClick={() => childtoggleStatus(index)}
                        className={`h-[22px] w-[46px] mr-2 rounded-[20px] cursor-pointer flex items-center ${
                          item.status === 'inactive'
                            ? 'bg-[#D9D9D980]'
                            : 'bg-[#37BD4A]'
                        }`}
                      >
                        <div
                          className={`h-[18px] w-[18px] rounded-full transition-transform ${
                            item.status === 'inactive'
                              ? 'translate-x-[2px] bg-white'
                              : 'translate-x-[24px] bg-white'
                          }`}
                        ></div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className='text-center text-gray-500'>No items found.</p>
                )}
              </div>

              {/* Close Button */}
              <div className='flex justify-end mt-8 mr-3'>
                <button
                  onClick={closeDialog}
                  className='bg-[#7C50DD] rounded-[39px] text-white w-[139px] h-[39px]'
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 overflow-auto'>
        {showcategory &&
          (loading
            ? Array.from({ length: categories.categoryCount }).map(
                (_, index) => <CategorySkeleton key={index} />
              )
            : listcategory.map((item, index) => (
                <div
                  key={item.name}
                  className='bg-[#F8F7F7] sm:h-[80px] h-auto w-full p-5 flex flex-col sm:flex-row items-center justify-between rounded-[34px] md:rounded-[84px]'
                >
                  <div className='block w-full sm:w-auto'>
                    <div className='mb-2 bg-opacity-5 h-[17px] rounded-[20px] w-full'>
                      <p className='font-bold text-[17px]'>{item.name}</p>{' '}
                      {/* Render the name of the item */}
                    </div>
                    <div
                      onClick={() => viewpages(index)}
                      className='cursor-pointer bg-opacity-5 h-[14px] flex justify-start space-x-1 gap-2'
                    >
                      <div className='flex'>
                      <p className='text-[11px]'>
                        {item.activeCount}/
                      </p>
                        <p className='text-[11px] space-x-2'>
                          {Array.isArray(item.data) && item.data
                            ? item.data.length
                            : 0}{' '}
                          <span>pages</span>
                        </p>
                        <svg
                          width='13'
                          height='13'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.85453 13.3333H13.7454M10.8 2.33334C11.0604 2.06813 11.4136 1.91913 11.7818 1.91913C11.9641 1.91913 12.1447 1.95571 12.3132 2.02678C12.4816 2.09785 12.6347 2.20202 12.7636 2.33334C12.8926 2.46466 12.9948 2.62057 13.0646 2.79215C13.1344 2.96373 13.1703 3.14762 13.1703 3.33334C13.1703 3.51906 13.1344 3.70296 13.0646 3.87454C12.9948 4.04612 12.8926 4.20202 12.7636 4.33334L4.5818 12.6667L1.96362 13.3333L2.61817 10.6667L10.8 2.33334Z'
                            stroke='#252530'
                            strokeWidth='1.6'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                      
                    </div>
                  </div>

                  <div className='flex items-center justify-between sm:justify-end w-full sm:w-auto mt-3 sm:mt-0'>
                    <div className='flex items-center justify-between sm:justify-end w-full'>
                      <div
                        onClick={() => toggleStatus(index)}
                        className={`h-[22px] w-[46px] mr-2 rounded-[20px] cursor-pointer flex items-center ${
                          item.status === 'active'
                            ? 'bg-[#37BD4A]'
                            : 'bg-[#D9D9D980]'
                        }`}
                      >
                        <div
                          className={`h-[18px] w-[18px] rounded-full transition-transform ${
                            item.status === 'active'
                              ? 'translate-x-[24px] bg-white'
                              : 'translate-x-[2px] bg-white'
                          }`}
                        ></div>
                      </div>
                      <button
                        onClick={() => viewpages(index)}
                        className='w-[81px] h-[27px] text-[#FFFFFF] rounded-[48px] font-[500] text-[10.34px] leading-[12px] bg-[#7C50DD]'
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              )))}
      </div>

      {showcategory && (
        <div className='flex justify-end mt-5 '>
          <button
            onClick={handlepassdata}
            className='bg-[#7C50DD] border-[1px] border-[#7C50DD] w-[166px] h-[48px] rounded-[59px] p-[10px] text-[14px] leading-[19.12px] font-[700] text-white'
          >
            Save
          </button>
        </div>
      )}
    </div>
  )
}

export default ListData
