import { useState, useEffect } from "react";

const useChatbotLoader = (src, storeID, { cleanup = false, appendTo = "body" } = {}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {

    if (!src) {
      window.dispatchEvent(new CustomEvent("removescript", { detail: true }))
      return;
    }

    let script = document.querySelector(`script[src="${src}"]`);
    let shouldAppend = false;

    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-store-id", storeID);
      script.setAttribute("id", "chatbot-script-loader");
      script.setAttribute("defer", "")
      shouldAppend = true;
      // if (window.chatbotConfig) {
      //   window.chatbotConfig = "";
      // }
    } else if (script.hasAttribute("chatbot-script-loader")) {
      setLoaded(true);
      return;
    }

    if (!sessionStorage.getItem("store_id")) {
      console.log("script  removed")
    }

    const onScriptLoad = () => {
      script.setAttribute("data-store-id", storeID);
      setLoaded(true);
    };

    const onScriptError = () => {
      if (cleanup) {
        script.remove();
      }
      setLoaded(false);
    };



    script.addEventListener("load", onScriptLoad);
    script.addEventListener("error", onScriptError);

    if (shouldAppend) {
      document[appendTo === "body" ? "body" : "head"].appendChild(script);
    }

    const onScriptremove = (event) => {
      if (event.detail) {

        script.remove()
        window.location.reload()
      }

    }

    window.addEventListener("removescript", onScriptremove)




    return () => {
      if (cleanup) {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
        window.removeEventListener("removescript", onScriptremove)
        script.remove();
      }
    };
  }, [src, storeID, loaded, appendTo, cleanup]);

  useEffect(() => {


  }, [src])


  return loaded;
};

export default useChatbotLoader;
