import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import chatbotlogo from "../../assets/chatbotlogo.png";
import keyicon from "../../assets/keyicon.png";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { apiFetch } from "../../services/apihelper";

const ForgotPassword = () => {
    const API_URL = process.env.REACT_APP_API_URL+"/api/";
    const [email, setEmail] = useState("");
    // eslint-disable-next-line
    const navigate = useNavigate(); // Initialize useNavigate
    // eslint-disable-next-line
    const [settoken, token] = useState("");

    // API call to request a password reset token
    const requestResetToken = async (email) => {
        console.log(email)
        try {
            const response = await apiFetch('users/generate-reset-token', {
                method: 'POST',
                body: JSON.stringify({ email }), // Pass the email in the request body
            });

            if (!response.success) {
                throw new Error('Failed to send reset link');
            }

            const data = await response;

            if(data.success){
                toast.success("Please check you email to reset your password", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                toast.error("Failed to send reset link. Please try again later.", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            console.log("Token:", data.resetToken);
            return data.resetToken; // Assuming the token is returned in the response
        } catch (error) {
            console.error("Error:", error);
            throw error; // Rethrow the error for handling in handleSubmit
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === "") {
            // Show toast notification for empty email
            toast.error("Please enter your email address", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            try {
                const token = await requestResetToken(email);
                // toast.success("Password reset link sent to your email", {
                //     position: "bottom-center",
                //     autoClose: 3000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });

                // Navigate to the Reset Password page with the token
                // navigate(`/reset-password?token=${token}`);
                // window.open(`/reset-password?token=${token}`, '_blank');
            } catch (error) {
                toast.error("Failed to send reset link. Please try again later.",{
                    position: "bottom-center"
                });
            }
        }
    };

    return (
        <div className="">
            <div className="flex flex-col items-center justify-center min-h-screen relative p-5">
                {/* Back to login button positioned at the top left */}
                <div className="absolute top-4 left-4 flex items-center">
                    <a href="/" className="flex">
                        <svg width="21" height="21" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.8656 5.19692C19.3781 5.70949 19.3781 6.54051 18.8656 7.05308L11.9187 14L18.8656 20.9469C19.3781 21.4595 19.3781 22.2905 18.8656 22.8031C18.353 23.3156 17.522 23.3156 17.0094 22.8031L9.13442 14.9281C8.62186 14.4155 8.62186 13.5845 9.13442 13.0719L17.0094 5.19692C17.522 4.68436 18.353 4.68436 18.8656 5.19692Z" fill="#373737" />
                        </svg>
                        <p className="font-[500] text-[14px] ml-2 leading-5">Back to login</p>
                    </a>
                </div>

                <div className="flex justify-center items-start w-full max-w-md">
                    <div className="block w-full mt-10">
                        <div className="flex justify-center items-start mb-3">
                            <img src={chatbotlogo} alt="chatedge" className="w-auto h-[55px] mx-auto" />
                        </div>
                        <div className="flex justify-center mt-4 items-center">
                            <div className="block text-center mb-4">
                                <h2 className='font-[600] text-[20px] 2xl:text-[19px] 3xl:text-[18px] 4xl:text-[18px] leading-[24px] text-[#000]'>Forgot password?</h2>
                                <p className="font-[400] text-[14px] mt-4">Enter the email address associated with your account.</p>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <img src={keyicon} alt="" className="w-[76px] h-[76px]" />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="block mb-4">
                                <p className="font-[400] text-[14px] leading-[14px] text-[#333333] mb-1">Email</p>
                                <input
                                    type="text"
                                    className="w-full h-[48px] p-2 rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="flex justify-center mt-5 items-start">
                                <button type="submit" className="bg-[#7C50DD] w-full h-[48px] rounded-[55px] text-[#FFFFFF] text-[14px] leading-[21px] font-[600]">
                                    Reset Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* Toast container to display toast messages */}
            <ToastContainer />
        </div>
    );
};

export default ForgotPassword;
