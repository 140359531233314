import React from 'react'
import logo from '../../assets/navbaricon.png'
import { Link } from 'react-router-dom'

const TermsPage = () => {
  return (
    <div className='min-h-screen bg-gray-50'>
      {/* Header Section */}
      <header className=' bg-black text-white py-4'>
        <div className='max-w-7xl mx-auto px-6 flex justify-between items-center'>
          <div className='flex items-center space-x-4'>
            <img src={logo} alt='Logo' className='h-8 w-auto' />
          </div>
          <div className='flex space-x-4'>
            <button className='text-[12px] font-semibold hover:underline'>
              {' '}
              <Link to='/'>Log in</Link>
            </button>
            <button className='bg-[#FFFFFF] text-black px-4 py-2 text-[12px] rounded font-semibold flex justify-center items-center hover:bg-black hover:text-white'>
              <Link to='/register'>Sign up</Link>
            </button>
          </div>
        </div>
      </header>

      {/* Terms and Conditions Section */}
      <main className='py-12 px-6'>
        <div className='min-h-screen bg-gray-100 p-8'>
          <div className='max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6'>
            <h1 className='text-2xl font-bold text-gray-800 mb-4'>
              Terms and Conditions
            </h1>

            <div className='space-y-6'>
              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Introduction
                </h2>
                <p className='text-gray-600'>
                Welcome to MagBot Chatbot! These Terms and Conditions govern your use of the MagBot Chatbot app and services. By using the app, you agree to abide by these terms. If you do not agree, please discontinue using the app.
                </p>
              </section>
              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Definitions
                </h2>
                <p className='text-gray-600'>
                  "We," "Our," or "Us" refers to MagBot. "You" or "Your" refers
                  to the merchant or user accessing the app. "Services" refers
                  to the functionalities provided by MagBot Chatbot.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Eligibility
                </h2>
                <p className='text-gray-600'>
                  You must be 18 years or older and have the authority to bind
                  your business to these terms to use MagBot.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Use of Services
                </h2>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>You agree to use the app only for lawful purposes.</li>
                  <li>
                    You shall not misuse the app, attempt to gain unauthorized
                    access, or disrupt service operations.
                  </li>
                  <li>
                    You are responsible for the data and content processed
                    through MagBot.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Merchant Responsibilities
                </h2>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    Ensure customer data processed through the app complies with
                    applicable privacy laws.
                  </li>
                  <li>Provide accurate store and contact information.</li>
                  <li>
                    Notify us immediately of any unauthorized use of your
                    account or security breaches.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Intellectual Property
                </h2>
                <p className='text-gray-600'>
                  MagBot owns all rights to the app, including trademarks,
                  content, and technology. You may not copy, modify, or
                  redistribute any part of the app without written consent.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Data and Privacy
                </h2>
                <p className='text-gray-600'>
                  Your use of the app is subject to our{' '}
                  <a className='text-blue-600 hover:underline' href='/pages/privacy-policy'>
                    Privacy Policy
                  </a>{' '}
                  . By using MagBot, you consent to the collection and use of
                  data as outlined in the policy.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Limitation of Liability
                </h2>
                <p className='text-gray-600'>
                  MagBot is provided "as-is" without warranties of any kind,
                  express or implied. We are not responsible for any direct,
                  indirect, or consequential damages arising from the use of the
                  app.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Termination
                </h2>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    We reserve the right to suspend or terminate your access to
                    the app if you violate these terms.
                  </li>
                  <li>You may discontinue using the app at any time.</li>
                </ul>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Changes to the Terms
                </h2>
                <p className='text-gray-600'>
                  We may update these Terms and Conditions periodically.
                  Continued use of the app constitutes acceptance of the updated
                  terms.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Contact Us
                </h2>
                <p className='text-gray-600'>
                  For questions or concerns about these Terms and Conditions,
                  please contact us at:
                </p>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    Email:{' '}
                    <a  href="mailto:support@magbot.ai" className='text-blue-600 hover:underline'>
                      support@magbot.ai
                    </a>{' '}
                  </li>
                  <li>
                    Website:{' '}
                    <a
                      href='https://magbot.ai'
                      className='text-blue-600 hover:underline'
                    >
                      https://magbot.ai
                    </a>
                  </li>
                </ul>
                <p className='text-gray-600'>
                  By using MagBot Chatbot, you agree to these Terms and
                  Conditions. Thank you for choosing MagBot to enhance your
                  business!
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default TermsPage
