import React, { useState,useEffect } from 'react';
import ChatBotIntegration from './component/addchatbot';
import SetupChatBot from './component/setupchatbot';
import GetShopifyURl from './component/shopify/getshopifyurl';

const AddChatBot = () => {
    const [activeComponent, setActiveComponent] = useState(0);
    const handleAddChatBot = () => {
        setActiveComponent(1); 
    };
    const handlebacktochatbot = () => {
        setActiveComponent(0); 
    }
    const handlebacktosetupchatbot = () => {
        setActiveComponent(1);
    }
    const handleshopify = () => {
        localStorage.setItem('chatbot', 'Shopify');
        setActiveComponent(2);
    }

    useEffect(() => {
       if(sessionStorage.getItem('onboarding')){
        setActiveComponent(2);
       }else{
        setActiveComponent(0);
       }

    },[]);

    return (
        <div className="p-5">
            <div className="block mt-2">
                {activeComponent === 0 && (
                    <ChatBotIntegration onAdd={handleAddChatBot} />
                )}
                {
                    activeComponent === 1 && (
                        <SetupChatBot handleback={handlebacktochatbot} handleshopify={handleshopify}/>
                    )
                }
                {
                    activeComponent === 2 && (
                        <div className='p-4'>
                        <GetShopifyURl handleback={handlebacktosetupchatbot} />
                        </div>
                    )
                }   
                
            </div>
        </div>
    );
}

export default AddChatBot;
