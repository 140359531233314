import React, { useEffect, useState } from "react";
import Sidebar from "./component/sidebar";
import MainContent from "./component/maincontent";

const Training = () => {
  const [active, setActive] = useState(1);
  
  
  return (
    <div className="min-h-[29.1rem] h-full relative block md:flex">
      <Sidebar active={active} setActive={setActive} />

      <MainContent active={active} />
    </div>
  );
};

export default Training;
