import { json } from 'react-router-dom';
import { apiFetch } from '../apihelper';  // Import your apiFetch helper
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import Toastify styles


export const registrationverification = async (data) =>{
  try {
    const response = await apiFetch(
      'auth/generate-verification-code',{
        method: 'POST',
        body:JSON.stringify(data)
      }
    );
    return response;
  } catch (error) {
  }
}

export const postDataToStore = async (data) => {
  // console.log(data)
  try {
    // Use apiFetch to send the POST request
    const response = await apiFetch('store', {
      method: 'POST',
      body: JSON.stringify(data),  // apiFetch automatically handles headers
    });
    return response;  // Return the response data directly
  } catch (error) {
    // console.error('Error making API call:', error.message);
    toast.error('Access Denied.', {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
};

export const UpdateMetaStore = async (data,storeId) => {
  // console.log(data)
  try {
    // Use apiFetch to send the POST request
    const response = await apiFetch(`store/${storeId}`, {
      method: 'PUT',
      body: JSON.stringify(data),  // apiFetch automatically handles headers
    });
    return response;  // Return the response data directly
  } catch (error) {
    // console.error('Error making API call:', error.message);
    toast.error('Access Denied.', {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    throw error;  // Re-throw the error so it can be handled elsewhere
  }
};

export const getshopifylist = async (id) => {
  try {
    // Array of types
    const types = ['products', 'collections', 'articles', 'pages'];

    // Dynamically create the apiUrls array using the types
    const apiUrls = types.map(type => `shopify/${id}/type/${type}`);

    const promises = apiUrls.map((url) =>
      apiFetch(url, {
        method: 'GET',
      })
    );

    // Wait for all API calls to resolve
    const responses = await Promise.all(promises);

    // Assuming that each API returns a JSON response, map over the responses to parse them
    const data = await Promise.all(responses.map(async (response) => {
      if (response.json) {
        return await response.json(); // If response has json method
      }
      return response; // Return as is if not a standard fetch response
    }));

    // Combine all the data into one object in the desired order
    const UrlData = {
      products: data[0],
      collections: data[1],
      articles: data[2],
      pages: data[3],
      categoryCount: 5,
      totalItemCount: 0
    };

    const categoryCount = Object.keys(UrlData).length - 2;  // Subtracting 'policies' and 'categoryCount'

    const totalItemCount = Object.values(UrlData).reduce((sum, currentArray) => {
      return sum + (Array.isArray(currentArray) ? currentArray.length : 0);
    }, 0);

    UrlData.categoryCount = categoryCount;
    UrlData.totalItemCount = totalItemCount;

    return UrlData;

  } catch (error) {
    // Handle any errors that occur during the API requests
    // toast.error('Failed to fetch Shopify data. Please try again.', {
    //   position: "bottom-center",
    //   autoClose: 3000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    // });
    throw error;  // Re-throw the error to be handled by the caller
  }
};

export const insertdataset = async (dataToSend) => {
  try {
    // Use apiFetch to send the POST request
    const response = await apiFetch('dataset/batch', {
      method: 'POST',
      body: JSON.stringify(dataToSend)
    });

    // Log the response to see what it's returning
    // console.log('Response from apiFetch:', response);

    // Check if response is already a parsed object or not
    let data;
    if (typeof response === 'object' && !(response instanceof Response)) {
      // If response is a plain object, assume it's already the parsed data
      data = response;
    } else if (response.json) {
      // If response has a .json() method, it's likely a Response object
      data = await response.json();
    } else {
      throw new Error('Unexpected response format');
    }

    if (response.ok || data.success) {  // Assuming apiFetch might not have response.ok
      toast.success('Dataset Created Successfully', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      console.error('Error sending data:', data);
      toast.error('Failed to send data', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return data;
  } catch (error) {
    console.error('Error:', error);
    toast.error('An error occurred while sending data', {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
};


export const trainDataset = async (idsArray) => {
  try {


    const response = await apiFetch('dataset/batch/train', {
      method: 'POST',
      body: JSON.stringify(idsArray),
    });

    let data;
    if (typeof response === 'object' && !(response instanceof Response)) {
      // If response is a plain object, assume it's already the parsed data
      data = response;
    } else if (response.json) {
      // If response has a .json() method, it's likely a Response object
      data = await response.json();
    } else {
      throw new Error('Unexpected response format');
    }

    // if (response.success === true) {
    //   toast.success('Training started successfully!', {
    //     position: "bottom-center",
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //   });
    // } else {
    //   console.error('Error starting training:', data);
    //   toast.error('Failed to start training', {
    //     position: "bottom-center",
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //   });
    // }

    return data;

  } catch (error) {
    console.error('Error starting training:', error);
    toast.error('An error occurred while starting training', {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    throw error;
  }
};


class GetDataSet {
  constructor(id, limit, order, type,page) {
    this.baseUrl = "dataset";
    this.storeId = sessionStorage.getItem("store_id");
    this.id = id || "";
    this.limit = limit || "";
    this.order = order || "";
    this.type = type || "";
    this.page = page || "";
  }

  // Method to construct the main API URL with optional query parameters
  constructUrl() {
    const params = new URLSearchParams();

    if (this.id) {
      params.append("dataset_id", this.id);
    }

    if (this.storeId) {
      params.append("store_id", this.storeId);
    }
    if (this.limit) {
      params.append("limit", this.limit);
    }
    if (this.order) {
      params.append("order", this.order);
    }
    if (this.type) {
      params.append("type", this.type);
    }
    if(this.page) {
      params.append("page", this.page);
    }

    const queryString = params.toString();
    return `${this.baseUrl}${queryString ? `?${queryString}` : ""}`;
  }

  

  // Method to fetch data from API
  async fetchData() {
    const url = this.constructUrl(); // Use the constructed URL
    console.log(url)
    try {
      const response = await apiFetch(url, {
        method: 'GET',
      });

      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }

  //Method to search data with dataset title

  async searchData(searchQuery,type) {
    console.log(type)
    const params = new URLSearchParams();

    if (this.storeId) {
      params.append("store_id", this.storeId);
    }
    if (searchQuery) {
      params.append("search", searchQuery);  // Add the search query as a URL parameter
    }

    if(type){
      params.append("type", type)
    }

    const url = `${this.baseUrl}?${params.toString()}`;  // Construct the URL with parameters

    try {
      const response = await apiFetch(url, {
        method: 'GET',
      });

      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the search operation:", error);
      throw error;
    }
  }

  // Method to Delete single data

  async singleDelete(id) {
    const url = `${this.baseUrl}/${id}`;

    try {
      const response = await apiFetch(url, {
        method: 'DELETE',
      });

      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }


      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }

  // Methos to Delete Multiple data
  async multipleDelete(idsArray) {
    const requestBody = {
      "ids": idsArray
    };

    try {
      const response = await apiFetch(`${this.baseUrl}/batch/delete`, {
        method: 'POST',
        body: JSON.stringify(requestBody)
      });

      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the delete operation:", error);
      throw error;
    }
  }

  async setUntrained(idsArray) {
    console.log(idsArray)
    const requestBody = {
      "ids": idsArray,
      "status": "inactive"
    };
    const url = `${this.baseUrl}/batch/train`;

    try {
      const response = await apiFetch(url, {
        method: 'POST',
        body: JSON.stringify(requestBody)
      });

      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }

  async setTrained(idsArray) {
    const requestBody = {
      "ids": idsArray,
      "status": "active"
    };
    const url = `${this.baseUrl}/batch/train`;

    try {
      const response = await apiFetch(url, {
        method: 'POST',
        body: JSON.stringify(requestBody)
      });

      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }

  // Method to get counts for each category in training_category
  async GetCount() {
    const training_category = [
      localStorage.getItem("chatbot"), // Value stored in localStorage under "chatbot"
      "article",
      "faq",
      "unmatched"
    ];

    // Create an array of API promises to fetch count data for each category
    const fetchPromises = training_category.map(async (category) => {
      const params = new URLSearchParams();

      if (this.storeId) {
        params.append("store_id", this.storeId);
      }
      if (category) {
        params.append("type", category);
      }

      const url = `${this.baseUrl}?${params.toString()}`;

      try {
        const response = await apiFetch(url, {
          method: 'GET',
        });

        let data;
        if (typeof response === 'object' && !(response instanceof Response)) {
          data = response;
        } else if (response.json) {
          data = await response.json();
        } else {
          throw new Error('Unexpected response format');
        }

        return { category, count: data.pagination.total }; // Assuming the response contains a count field
      } catch (error) {
        console.error(`Error fetching count for category: ${category}`, error);
        return { category, count: 0 }; // Return 0 if there was an error
      }
    });

    // Wait for all promises to resolve and return the result
    const counts = await Promise.all(fetchPromises);
    return counts;
  }

  // Method to update meta and title

  async updateMetaTitle(id, title, content, type) {
    const requestBody = {
      "title": title,
      "type": type,
      "meta": {
        "data": {
          "title": title,
          "content": content
        }
      }

    };

    const url = `${this.baseUrl}/${id}`;

    try {
      const response = await apiFetch(url, {
        method: 'PUT',
        body: JSON.stringify(requestBody)
      });

      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }


  async updateMetaTitleFaq(id, title, content, type) {
    const requestBody = {
      "title": title,
      "type": type,
      "meta": {
        "data": {
          "question": title,
          "answer": content
        }
      }

    };

    const url = `${this.baseUrl}/${id}`;

    try {
      const response = await apiFetch(url, {
        method: 'PUT',
        body: JSON.stringify(requestBody)
      });

      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }


}

export default GetDataSet;


export const getStore = async () => {
  const response = await apiFetch(`store?user_id=${JSON.parse(sessionStorage.getItem('user')).id}`, {
    method: 'GET',
  });

  let data;
  if (typeof response === 'object' && !(response instanceof Response)) {
    data = response;
  } else if (response.json) {
    data = await response.json();
  } else {
    throw new Error('Unexpected response format');
  }

  return data;
};


export const getStorebyID = async (storeId, navigate, logout) => {
  try {
    const response = await apiFetch(`store/${storeId}`, {
      method: 'GET',
    });

    let data;
    if (typeof response === 'object' && !(response instanceof Response)) {
      data = response;
    } else if (response.json) {
      data = await response.json();
    } else {
      throw new Error('Unexpected response format');
    }

    if(data.message === "Invalid token" && navigate){
      navigate("/")
      logout()
    }
    return data;
  } catch (error) {
    console.error('Error deleting store:', error);
    throw error;
  }
};

export const deleteStorebyID = async (storeId) => {
  try {
    const response = await apiFetch(`store/${storeId}`, {
      method: 'DELETE',
    });

    let data;
    if (typeof response === 'object' && !(response instanceof Response)) {
      data = response;
    } else if (response.json) {
      data = await response.json();
    } else {
      throw new Error('Unexpected response format');
    }

    return data;
  } catch (error) {
    console.error('Error deleting store:', error);
    throw error;
  }
};


export const authShopify = async (url) => {
  const sanitizedUrl = url.trim();
  const shopify_auth_redirect_host = process.env.REACT_APP_SHOPIFY_FRONTEND_HOST || "http://localhost:3001";
  console.log("shopify_auth_redirect_host", shopify_auth_redirect_host);
  const response = `${process.env.REACT_APP_API_URL}/api/shopify/install?shop=${sanitizedUrl}&redirectUrl=${shopify_auth_redirect_host}`;
  return response
}


export class GetChatLog {

  constructor() {
    this.baseUrl = "chatlog";

  }

  async Get_Customer_List(store_id,signal) {
    // console.log(store_id)
    try {
      const response = await apiFetch(`${this.baseUrl}/customer-list?limit=10&store_id=${store_id}`, {
        method: 'GET',
        signal: signal,  // Pass the signal here to allow aborting the request
      });
      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the Get_Customer_List operation:", error);
    }
  }

  async Get_Chat_List(store_id, customer_id,signal) {
    try {
      const response = await apiFetch(`${this.baseUrl}/chat-list?limit=10&store_id=${store_id}&customer_id=${customer_id}`, {
        method: 'GET',
        signal: signal,  // Pass the signal here to allow aborting the request
      });
      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the chat-list operation:", error);
    }
  }



  async Get_Chat_Log(store_id, chatlist_id,signal) {
    try {
      const response = await apiFetch(`${this.baseUrl}?limit=10&store_id=${store_id}&chat_id=${chatlist_id}`, {
        method: 'GET',
        signal: signal,  // Pass the signal here to allow aborting the request
      });
      let data;
      if (typeof response === 'object' && !(response instanceof Response)) {
        data = response;
      } else if (response.json) {
        data = await response.json();
      } else {
        throw new Error('Unexpected response format');
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the chat-log operation:", error);
    }
  }

}




export const Get_tickets = async (store_id, customer_id, limit,status,page,search,ticket_number,sort,customer_search,created_at_from,created_at_to) => {
  // Build the query string dynamically
  const queryParams = new URLSearchParams({
    ...(store_id && {store_id}),
    ...(customer_id && { customer_id }) ,
    ...(limit && { limit }), // Add limit only if it's provided
    ...(status && { status }), 
    ...(page && { page }),
    ...( search && { search }),
    ...( ticket_number && { ticket_number }),
    ...( sort && { sort }),
    ...( customer_search && { customer_search }),
    ...( created_at_from && { created_at_from }),
    ...( created_at_to && { created_at_to })

  });

  

  // Fetch the tickets
  const response = await apiFetch(`tickets?${queryParams.toString()}`, {
    method: 'GET',
  });

  return response;
};

// export const Search_tickets = async (store_id, customer_id, user_id, limit, status, search) => {
//   // Build the query string dynamically
//   const queryParams = new URLSearchParams({
//     ...(store_id && {store_id}),
//     ...(customer_id && { customer_id }) ,
//     ...(user_id && { user_id }),
//     ...(limit && { limit }), // Add limit only if it's provided
//     ...(status && { status }),
//     ...(search && { search }),

//   });

//   // Fetch the tickets
//   const response = await apiFetch(`tickets?${queryParams.toString()}`, {
//     method: 'GET',
//   });

//   return response;
// };



export const update_tickets = async (ticket_id,status)=>{
  // const queryParams = new URLSearchParams({
  //   ...(ticket_id && { ticket_id }),
  // });

  //update the tickets
  const response = await apiFetch(`tickets/${ticket_id}`, {
    method: 'PUT',
    body: JSON.stringify({"status":status,
      "store_id": sessionStorage.getItem('store_id')
    })
  });

  return response

}

export const delete_tickets = async (ticket_id)=>{
  const queryParams = new URLSearchParams({
    ...(ticket_id && { ticket_id }),
  });

  //update the tickets
  const response = await apiFetch(`tickets/${ticket_id}`, {
    method: 'DELETE',
  });

  return response

}


export const getUserbyID = (id) =>{
  const response = apiFetch(`users?id=${id}`, {
    method: 'GET',
  });

  return response
}

// export const getstorelistbyID  = (id) =>{
//   const response = apiFetch(`/store/${id}`,{
//     method: 'GET',
//   });

//   return response

// }

export const feedback = async (data) => {
  try {
    // Destructure the required fields from the input data object
    const { store_id, Name, Contact_Email, Rating, Suggestion, Images } = data;
    // Prepare the request body
    const requestBody = {
      store_id,
      Name,
      Contact_Email,
      Rating,
      Suggestion,
      Images,
    };

    // Send the POST request
    const response = await apiFetch('feedback/submit', {
      method: 'POST',
      body: JSON.stringify(requestBody), // Send the request body as JSON
    });

    // Check the response format and parse the data
    let responseData;
    if (typeof response === 'object' && !(response instanceof Response)) {
      responseData = response;
    } else if (response.json) {
      responseData = await response.json();
    } else {
      throw new Error('Unexpected response format');
    }

    return responseData;
  } catch (error) {
    console.error("There was a problem with the feedback submission:", error);
  }
};



export const getsummarizedata = async (store_id) => {
  try {
    const response = await apiFetch(`store/${store_id}/homepage/summerize`, {
      method: 'GET',
    });

    let responseData;
    if (typeof response === 'object' && !(response instanceof Response)) {
      responseData = response;
    } else if (response.json) {
      responseData = await response.json();
    } else {
      throw new Error('Unexpected response format');
    }

    return responseData;
  } catch (error) {
    console.error("There was a problem with the feedback submission:", error);
  }
};
