import React from 'react'
import logo from '../../assets/navbaricon.png'
import { Link } from 'react-router-dom'

const Privacy = () => {
  return (
    <div className='min-h-screen bg-gray-50'>
      {/* Header Section */}
      <header className=' bg-black text-white py-4'>
        <div className='max-w-7xl mx-auto px-6 flex justify-between items-center'>
          <div className='flex items-center space-x-4'>
            <img src={logo} alt='Logo' className='h-8 w-auto' />
          </div>
          <div className='flex space-x-4'>
            <button className='text-[12px] font-semibold hover:underline'>
              {' '}
              <Link to='/'>Log in</Link>
            </button>
            <button className='bg-[#FFFFFF] text-black px-4 py-2 text-[12px] rounded font-semibold flex justify-center items-center hover:bg-black hover:text-white'>
              <Link to='/register'>Sign up</Link>
            </button>
          </div>
        </div>
      </header>

      {/* Terms and Conditions Section */}
      <main className='py-12 px-6'>
        <div className='min-h-screen bg-gray-100 p-8'>
          <div className='max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6'>
            <h1 className='text-2xl font-bold text-gray-800 mb-4'>
              Privacy Policy
            </h1>

            <div className='space-y-6'>
              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Introduction
                </h2>
                <p className='text-gray-600'>
                  At MagBot Chatbot, we value your privacy and are committed to
                  protecting your personal information. This Privacy Policy
                  explains how we collect, use, and safeguard the information
                  provided when you use our app.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Information We Collect
                </h2>
                <p className='text-gray-600 font-semibold'>
                  Merchant Information:
                </p>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    Store name, URL, and contact details (email, phone number,
                    if provided).
                  </li>
                </ul>
                <p className='text-gray-600 font-semibold'>
                  Customer Information (processed on behalf of the merchant):
                </p>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    Customer names, email addresses, and order details collected
                    during interactions with the chatbot.
                  </li>
                  <li>
                    Chat data from conversations between customers and the
                    chatbot.
                  </li>
                </ul>
                <p className='text-gray-600 font-semibold'>App Usage Data:</p>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    Logs, error reports, and analytics data to improve app
                    performance and user experience.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  How We Use the Information
                </h2>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    To operate and enhance the functionality of MagBot Chatbot.
                  </li>
                  <li>To provide support and resolve issues.</li>
                  <li>
                    To analyze app performance and improve user interactions.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Data Sharing and Disclosure
                </h2>
                <p className='text-gray-600'>
                  We do not sell or share your data with third parties, except
                  in the following cases:
                </p>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    <strong>Service Providers:</strong> Trusted third parties
                    that assist with app functionality.
                  </li>
                  <li>
                    <strong>Legal Compliance:</strong> If required by law or in
                    response to legal processes.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Data Security
                </h2>
                <p className='text-gray-600'>
                  We implement security measures such as encryption and access
                  controls to protect your information from unauthorized access,
                  alteration, or disclosure.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Retention of Data
                </h2>
                <p className='text-gray-600'>
                  We retain data only as long as necessary to provide our
                  services or comply with legal obligations. Merchants may
                  request deletion of their data at any time.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Merchant and Customer Rights
                </h2>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    Merchants can update or delete their information via the app
                    or by contacting us.
                  </li>
                  <li>
                    Customers may request access to, correction, or deletion of
                    their data by contacting the merchant directly.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Third-Party Integrations
                </h2>
                <p className='text-gray-600'>
                  MagBot may integrate with third-party services. These services
                  have their own privacy policies, which we recommend reviewing.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Changes to This Policy
                </h2>
                <p className='text-gray-600'>
                  We may update this Privacy Policy periodically. Any
                  significant changes will be communicated through the app or
                  email.
                </p>
              </section>

              <section>
                <h2 className='text-xl font-semibold text-gray-700 mb-[10px]'>
                  Contact Us
                </h2>
                <p className='text-gray-600'>
                  For any questions or concerns about this Privacy Policy,
                  please contact us at:
                </p>
                <ul className='list-disc pl-6 text-gray-600'>
                  <li>
                    Email:{' '}
                    <a  href="mailto:support@magbot.ai" className='text-blue-600 hover:underline'>
                      support@magbot.ai
                    </a>{' '}
                  </li>
                  <li>
                    Website:{' '}
                    <a
                      href='https://magbot.ai'
                      className='text-blue-600 hover:underline'
                    >
                      https://magbot.ai
                    </a>
                  </li>
                </ul>
                <p className='text-gray-600'>
                  By using MagBot Chatbot, you agree to this Privacy Policy.
                  Thank you for trusting us with your information!
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Privacy
