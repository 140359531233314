import shopifybag from '../../../assets/shopifybag.png'
import webicon from '../../../assets/webicon.png'

const SetupChatBot = ({ handleback, handleshopify }) => {
    return (
        <div className="p-4">
            <div className="flex items-start justify-between">
                <div className="block">
                    <h1 className="text-[#252530] font-extrabold text-[23px] leading-[31px]">
                        Set Up Your Chatbot
                    </h1>
                    <p className="text-[12px]">
                        Train Your Chatbot to Enhance Customer Interactions on Any Platform
                    </p>
                </div>
                <button
                    className="border border-[#7C50DD] rounded-[39px] flex items-center justify-start text-[14px] font-normal p-[10px] h-[44px] bg-white w-[103px]"
                    aria-label="Go back"
                    onClick={handleback}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.1705 4.4545C16.6098 4.89384 16.6098 5.60616 16.1705 6.0455L10.216 12L16.1705 17.9545C16.6098 18.3938 16.6098 19.1062 16.1705 19.5455C15.7312 19.9848 15.0188 19.9848 14.5795 19.5455L7.8295 12.7955C7.39017 12.3562 7.39017 11.6438 7.8295 11.2045L14.5795 4.4545C15.0188 4.01517 15.7312 4.01517 16.1705 4.4545Z"
                            fill="#7C50DD"
                        />
                    </svg>
                    <span className="ml-2">Back</span>
                </button>
            </div>

            <div className="flex flex-col md:flex-row justify-start md:items-start  items-center mt-4  cursor-pointer">
                <div
                    onClick={handleshopify}
                    className="border-[1.6px] p-4 border-solid w-full md:w-[288px] max-w-[288px]  h-[161px] bg-[#F4F4F4] rounded-[24px] mb-4 md:mb-0"
                >
                    <div className="flex-col items-center justify-start">
                        <img src={shopifybag} alt="Shopify Icon" className="mx-auto" />
                        <p className="mt-2 font-extrabold text-[18px] text-center">Shopify</p>
                        <span className="text-[#252530] text-[12px] leading-[17px] text-center block">
                            Use your Shopify content to generate <br />
                            answers to your customer questions.
                        </span>
                    </div>
                </div>

                {/* <div className="border-[1.6px] p-4 border-solid w-full md:ml-5 md:w-[288px] h-[161px] bg-[#F4F4F4] rounded-[24px]">
                    <div className="flex-col items-center justify-start">
                        <img src={webicon} alt="Website Icon" className="mx-auto" />
                        <p className="mt-2 font-extrabold text-[18px] text-center">Website</p>
                        <span className="text-[#252530] text-[12px] leading-[17px] text-center block">
                            Crawl your website’s content to get answers to popular user questions
                        </span>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default SetupChatBot;
