import React, { useEffect, useState } from 'react'
import '../../../styles/training.css'
import GetDataSet from '../../../services/data'

const Sidebar = ({ active, setActive }) => {
  const [Counts, setCounts] = useState({
    chatbot: 0,
    article: 0,
    faq: 0,
    unmatched: 0
  })

  const handleItemClick = id => {
    setActive(id)
  }

  const chatbot = localStorage.getItem('chatbot') || 'Chatbot' // Default value if localStorage is null

  useEffect(() => {
    const fetchData = async () => {
      const data = new GetDataSet()
      try {
        // const counts = await data.GetCount();
        // console.log("Counts:", counts[0].count);
        // setCounts({
        //     chatbot: counts[0].count || 0,
        //     article: counts[1].count || 0,
        //     faq:counts[2].count || 0,
        //     unmatched: counts[3].count || 0
        // });
      } catch (error) {
        console.error('Error fetching category counts:', error)
      }
    }

    fetchData()
    // Event listener for the custom "datasetDeleted" event
    const handleDatasetDeleted = event => {
      if (event.detail === true) {
        fetchData()
      }
    }

    // Add event listener
    window.addEventListener('datasetDeleted', handleDatasetDeleted)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('datasetDeleted', handleDatasetDeleted)
    }
  }, []) // Only runs once when the component mounts
  // console.log("Counts:", Counts);

  useEffect(() => {}, [])

  return (
    <div className='w-full md:w-[190px] pt-10 pl-3 pr-3'>
      <h1 className='font-extrabold text-[23px] ml-2 leading-[48px]'>
        Training
      </h1>
      <div className='block md:mt-5 space-y-3'>
        {/* Flex container for mobile scrolling */}
        <div className='flex space-x-3 overflow-x-auto  md:flex-col md:space-x-0 lg:flex-col lg:space-x-0 lg:space-y-3 scrollbar-hide '>
          <div
            onClick={() => handleItemClick(1)}
            className={`cursor-pointer flex justify-between pt-2 pb-2 pl-3 bg-none ${
              active === 1 ? 'active-item' : ''
            }`}
          >
            <p className='font-[500] text-[14px] leading-[24px] whitespace-nowrap'>
              Shopify
            </p>
          </div>

          <div
            onClick={() => handleItemClick(2)}
            className={`cursor-pointer flex justify-between pt-2 pb-2 pl-3 ${
              active === 2 ? 'active-item' : ''
            }`}
          >
            <p className='font-[500] text-[14px] leading-[24px] whitespace-nowrap'>
              Knowledge Base
            </p>
          </div>

          <div
            onClick={() => handleItemClick(3)}
            className={`cursor-pointer flex justify-between pt-2 pb-2 pl-3 ${
              active === 3 ? 'active-item' : ''
            }`}
          >
            <p className='font-[500] text-[14px] leading-[24px] whitespace-nowrap'>
              FAQs
            </p>
          </div>

          <div
            onClick={() => handleItemClick(4)}
            className={`cursor-pointer flex justify-between pt-2 pb-2 pl-3 ${
              active === 4 ? 'active-item' : ''
            }`}
          >
            <p className='font-[500] text-[14px] leading-[24px] whitespace-nowrap'>
              Unanswered
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
