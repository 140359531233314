// services/apiHelper.js
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_API_URL + "/api/";

// Object to store the state of ongoing API requests
const ongoingRequests = {};

// Toast ID to prevent duplicate messages
let toastId = null;

export const apiFetch = async (endpoint, options = {}) => {
    const requestKey = `${endpoint}:${JSON.stringify(options)}`;  // Unique key for the request

    if (ongoingRequests[requestKey]) {
        // Prevent duplicate requests
        return ongoingRequests[requestKey];
    }

    // Mark this request as ongoing
    const controller = new AbortController();
    const { signal } = controller;

    const requestPromise = new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('token');  // Get the token from sessionStorage
            const defaultHeaders = {
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
                // Only include 'Content-Type' header if the body is not FormData
                ...(options.body && options.body instanceof FormData
                    ? {} // Let the browser set the Content-Type for FormData automatically
                    : { 'Content-Type': 'application/json' })
            };

            const response = await fetch(`${BASE_URL}${endpoint}`, {
                ...options,
                signal: signal,
                headers: {
                    "ngrok-skip-browser-warning": "true",
                    ...defaultHeaders,
                    ...options.headers,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();

                if (response.status === 429) {
                    // Show a single toast message for 429 errors
                    if (!toast.isActive(toastId)) {
                        toastId = toast.warn(
                            errorData.message || 'Too many requests. Please try again later.',
                            {
                                position: "bottom-center",
                            }
                        );
                    }
                    return; // Exit early for 429 status
                }

                // Handle other errors
                if (!toast.isActive(toastId)) {
                    toastId = toast.error(
                        errorData.message || 'An error occurred.',
                        {
                            position: "bottom-center",
                        }
                    );
                }
                reject(new Error(errorData.message || 'An error occurred.'));
                return;
            }

            const responseData = await response.json();
            resolve(responseData);  // Resolve the promise with the API response data
        } catch (error) {
            if (!toast.isActive(toastId)) {
                toastId = toast.error(
                    error.message || 'Something went wrong',
                    {
                        position: "bottom-center",
                    }
                );
            }
            reject(error);  // Reject the promise on error
        } finally {
            // Once the request is complete (either success or failure), remove it from ongoingRequests
            delete ongoingRequests[requestKey];
        }
    });

    // Store the ongoing request promise to prevent duplicates
    ongoingRequests[requestKey] = requestPromise;
    return requestPromise;  // Return the promise of the ongoing request
};
