import shopifybag from '../../../../assets/shopifybag.png'
import React, { useState, useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css' // Import Toastify styles
import {
  postDataToStore,
  getshopifylist,
  insertdataset,
  trainDataset,
  authShopify,
  getsummarizedata
} from '../../../../services/data/index'
import CategorySkeleton from '../../../../components/cateogryskeleton'
import { useNavigate, useLocation } from 'react-router-dom'
import './chatbotloader.css'
import GetDataSet from '../../../../services/data/index'

const GetShopifyURl = ({ handleback }) => {
  const hasRun = useRef(false) // Ref to keep track if the effect has already run
  const [url, setUrl] = useState('')
  const [isTrimmed, setIsTrimmed] = useState(false)
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState()
  const [timeoutId, setTimeoutId] = useState(null)
  const [showcategory, setShowCategory] = useState(true)
  const [listcategory, setListCategory] = useState([]) // Initialize as an empty array
  const [showDialog, setShowDialog] = useState(false) // State for dialog visibility
  const [showitemdataset, setshowitemdataset] = useState(false)
  const [dataset, setdataset] = useState([])
  const [sessionMeta, setsessionMeta] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [showbotloader, setbotloader] = useState(false)
  const navigate = useNavigate()
  const [storeCreate, setstoreCreate] = useState(false)
  const [success, setsuccess] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [FilteredData, setFilteredData] = useState('')
  const [EditStoreInfoOpen, SetEditStoreInfoOpen] = useState(false)
  const [EditStoreInfoCategory, SetEditStoreInfoCategory] = useState(false)
  const [shopifySession, setShopifySession] = useState(null)
  const [store_id, Setstore_id] = useState('')
  const [summarizeData, SetsummarizeData] = useState([])
  const [summarizeFormData, SetsummarizeFormData] = useState("")
  const location = useLocation()
  const [summarizetitle, setsummarizeTitle] = useState('Store Information');
  const [summarizecontent, setsummarizeContent] = useState('');
  const fromSource = location.state?.fromSource || false
  const storeId = location.state?.storeId || null
  const from = location.state?.from || 'Guest'

  const [isToggled, setIsToggled] = useState(true)

  // Toggle handler
  const toggleStatusStoreInformation = async () => {
    setIsToggled(!isToggled)
  }

  useEffect(() => {
    if (fromSource && storeId) {
      // console.log("Valid Store ID:", storeId);
      setUrl(localStorage.getItem('url'))
      setDisable(true)
      Setstore_id(storeId) // Set store_id in state
      const GetUrlData = async () => {
        setLoading(true)
        try {
          // console.log("Fetching data for storeId:", storeId);
          const shopifyurldata = await getshopifylist(storeId) // Pass storeId directly
          // console.log("Fetched Shopify Data:", shopifyurldata);
          const summarizedata = await getsummarizedata(storeId)
          SetsummarizeData(summarizedata.data)
          SetEditStoreInfoCategory(true)
          setCategories(shopifyurldata)
          setShowCategory(true)
          setsuccess(true)

          sessionStorage.removeItem('onboarding')
          window.dispatchEvent(
            new CustomEvent('reloadusecredentials', { detail: true })
          )
        } catch (error) {
          console.error('Error fetching Shopify data:', error) // Detailed error logging
          toast.error('Failed to load data', {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
        } finally {
          setLoading(false) // Ensure loading state is cleared
        }
      }
      GetUrlData()
    }else{
      SetEditStoreInfoCategory(false)
    }
  }, [fromSource, storeId]) // Dependency array includes fromSource and storeId

  // useEffect(()=>{
  //     console.log(listcategory)
  // })

  // // Fetch the session from localStorage on mount
  // useEffect(() => {
  //   const handleShopifyAuth = event => {
  //     const receivedData = event.detail
  //     setShopifySession(receivedData)
  //   }

  //   // Listen for the custom event
  //   window.addEventListener('shopifyAuthSuccess', handleShopifyAuth)

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('shopifyAuthSuccess', handleShopifyAuth)
  //   }
  // }, []) // Runs only once when the component mounts

  useEffect(() => {
    const handleCallbackStoreId = event => {
      if (event && event.detail) {
        console.log('Received storeId:', event.detail)
      }
    }

    // Add event listener
    window.addEventListener('callbackStoreId', handleCallbackStoreId)
  }, []) // Empty dependency array ensures this runs only once

  const toggleStatus = async index => {
    const updatedCategory = listcategory[index]
    const newStatus =
      updatedCategory.status === 'active' ? 'inactive' : 'active'

    if (newStatus === 'inactive') {
      // When the parent is set to 'inactive', set all child items to 'inactive'
      const updatedData = updatedCategory.data.map(child => ({
        ...child,
        status: 'inactive' // All children set to 'inactive'
      }))

      // Recalculate active count after toggling child statuses
      const activeCount = updatedData.filter(
        child => child.status === 'active'
      ).length
      const totalCount = updatedData.length

      // Update selectedCategory with parent 'inactive' and all children 'inactive'
      setSelectedCategory({
        ...updatedCategory,
        status: newStatus, // Parent set to 'inactive'
        data: updatedData, // Children set to 'inactive'
        activeCount,
        totalCount
      })

      // Update the listcategory with parent 'inactive' and children 'inactive'
      setListCategory(prevList =>
        prevList.map((item, idx) =>
          idx === index
            ? {
                ...item,
                status: newStatus,
                data: updatedData,
                activeCount,
                totalCount
              }
            : item
        )
      )
    } else {
      // When the parent is set to 'active', set all child items to 'active'
      const updatedData = updatedCategory.data.map(child => ({
        ...child,
        status: 'active' // All children set to 'active'
      }))

      // Recalculate active count after toggling child statuses
      const activeCount = updatedData.filter(
        child => child.status === 'active'
      ).length
      const totalCount = updatedData.length

      // Update selectedCategory with parent 'active' and all children 'active'
      setSelectedCategory({
        ...updatedCategory,
        status: newStatus, // Parent set to 'active'
        data: updatedData, // Children set to 'active'
        activeCount,
        totalCount
      })

      // Update the listcategory with parent 'active' and children 'active'
      setListCategory(prevList =>
        prevList.map((item, idx) =>
          idx === index
            ? {
                ...item,
                status: newStatus,
                data: updatedData,
                activeCount,
                totalCount
              }
            : item
        )
      )

      const dataToSend = {
        categoryName: updatedCategory.name,
        data: updatedCategory.data // Adjust based on your API requirements
      }

      // You can handle any further logic for 'active' status here if needed
    }
  }

  // Update FilteredData whenever selectedCategory or searchQuery changes
  useEffect(() => {
    setFilteredData(
      selectedCategory?.data?.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      ) || []
    )
  }, [selectedCategory, searchQuery])

  const childtoggleStatus = filteredIndex => {
    // Find the actual index in selectedCategory.data
    const originalIndex = selectedCategory.data.findIndex(
      item => item.title === FilteredData[filteredIndex].title
    )

    // If the item is not found, exit the function
    if (originalIndex === -1) {
      console.log('Item not found in the original data.')
      return
    }

    const updatedCategory = { ...selectedCategory }
    const updatedData = [...updatedCategory.data]

    // Check if the item is not disabled
    if (!updatedData[originalIndex].disabled) {
      // Toggle the status: from 'inactive' to 'active' or 'active' to 'inactive'
      updatedData[originalIndex] = {
        ...updatedData[originalIndex],
        status:
          updatedData[originalIndex].status === 'inactive'
            ? 'active'
            : 'inactive'
      }

      const activeCount = updatedData.filter(
        child => child.status === 'active'
      ).length
      const totalCount = updatedData.length

      // Check if there are any active children
      const hasActiveChild = activeCount > 0

      // Check if all children are inactive
      const allChildrenInactive = updatedData.every(
        child => child.status === 'inactive'
      )

      // Update the selected category with the new data and determine parent status
      setSelectedCategory({
        ...updatedCategory,
        status: hasActiveChild ? 'active' : 'inactive',
        data: updatedData,
        activeCount,
        totalCount
      })

      // Update the corresponding category in the listcategory array
      setListCategory(prevList =>
        prevList.map(item => {
          if (item.name === updatedCategory.name) {
            return {
              ...item,
              status: allChildrenInactive ? 'inactive' : 'active',
              data: updatedData,
              activeCount,
              totalCount
            }
          }
          return item
        })
      )
    } else {
      console.log(
        `Item at index ${originalIndex} is disabled and cannot be toggled.`
      )
    }
  }

  useEffect(() => {
    // const activeCategories = listcategory.filter(item => item.status === 'active').map(item => ({
    //     categoryName: item.name,
    //     data: item.data // Keep only active categories' data
    // }));
    const activeCategories = listcategory.map(item => ({
      categoryName: item.name,
      data: item.data // Keep only active categories' data
    }))
    setdataset(activeCategories)
  }, [listcategory]) // Only runs when listcategory changes

  const handlepassdata = () => {
    localStorage.removeItem('shopifySession')
    localStorage.removeItem('url')
    // Check if dataset is an array
    if (Array.isArray(dataset)) {
      // Create an array to store the updated dataset
      const updatedDataset = dataset.map(i => {
        // For each category, map through its data (i.e., i.data)
        const items_data = i.data

        // console.log(items_data)

        // Modify the status of each item in the data
        const updatedItemsData = items_data.map(j => {
          // Toggle the status from 'inactive' to 'active' and vice versa
          // console.log(j.status)
          return {
            ...j,
            status: j.status === 'inactive' ? 'inactive' : 'active'
          }
        })

        // Return the updated category with updated items data
        return { ...i, data: updatedItemsData }
      })

      // Flatten the updated dataset to get all items in a single array of data
      const dataToSend = updatedDataset.flatMap(i => i.data)

      // Update the dataset state
      setdataset(updatedDataset)
      // console.log(dataToSend);

      //   console.log(updatedDataset)

      // Now, pass the `dataToSend` array to the API
      sendDataToAPI(dataToSend)
    }
  }

  // Function to send data to the API
  const sendDataToAPI = async dataToSend => {
    try {
      // setbotloader(true);  // Show loader initially

      const getdataset = await insertdataset(dataToSend) // Insert dataset and wait for the response
      // console.log("API Response Data:", getdataset.data);  // Log the response data

      if (getdataset.success && Array.isArray(getdataset.data)) {
        // Extract all dataset IDs without filtering for status
        const allIds = getdataset.data
          .filter(item => item.status === 'active')
          .map(item => item.dataset_id) // Extract dataset IDs
        sessionStorage.setItem('store_id', store_id)
        if (sessionStorage.getItem('store_id')) {
          navigate('/bot-customization')
        } else {
          return
        }

        if (allIds.length > 0) {
          // Prepare the payload with all IDs and a fixed status of "active"
          const payload = {
            status: 'active',
            ids: allIds
          }

          // Pass the payload to trainDataset function
          const trainDatasetResponse = await trainDataset(payload) // Train dataset with the payload

          if (trainDatasetResponse.success) {
            toast.success('Dataset trained successfully!', {
              position: 'bottom-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            })

            window.dispatchEvent(
              new CustomEvent('checkStoreId', { detail: true })
            )
            window.dispatchEvent(
              new CustomEvent('datatarined', { detail: true })
            )
          }
        } else {
          toast.info('No datasets found to train.', {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
          setbotloader(false)
        }
      } else {
        setbotloader(false)
        throw new Error('Unexpected API response or data structure.')
      }
    } catch (error) {
      console.error('Error sending data:', error) // Log any errors
      toast.error('Failed to load data', {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
      setbotloader(false)
    }
    //  finally {
    //     setbotloader(false);  // Ensure loader is hidden after the process
    // }
  }

  const viewpages = index => {
    const Category = listcategory[index]
    setSelectedCategory(Category)
    setshowitemdataset(true)
    // console.log(selectedCategory.data)
  }

  useEffect(() => {
    if (success) {
      toast.success('Data loaded successfully!', {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    }
  }, [success])

  useEffect(() => {
    if (showDialog) {
      setLoading(true)
    }
    if (loading) {
      const id = setTimeout(() => {
        setLoading(false)
      }, 1000)
      setTimeoutId(id)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [loading])

  useEffect(() => {
    // Update listcategory whenever categories change
    if (categories) {
      const updatedCategories = [
        {
          name: 'Products',
          data: categories.products || [],
          status: 'active',
          activeCount: (categories.products || []).length,
          totalCount: (categories.products || []).length
        },
        {
          name: 'Collections',
          data: categories.collections || [],
          status: 'active',
          activeCount: (categories.collections || []).length,
          totalCount: (categories.collections || []).length
        },
        {
          name: 'Articles',
          data: categories.articles || [],
          status: 'active',
          activeCount: (categories.articles || []).length,
          totalCount: (categories.articles || []).length
        },
        {
          name: 'Pages',
          data: categories.pages || [],
          status: 'active',
          activeCount: (categories.pages || []).length,
          totalCount: (categories.pages || []).length
        }
      ]

      // Ensure child elements are also updated to match the parent status
      const updatedListCategory = updatedCategories.map(category => ({
        ...category,
        data: category.data.map(child => ({
          ...child,
          status: category.status === 'active' ? 'active' : 'inactive' // Sync child status with parent
        }))
      }))

      setListCategory(updatedListCategory)
    }
  }, [categories])

  // useEffect(() => {
  //   const processShopifySession = async () => {
  //     if (shopifySession) {
  //       // console.log(shopifySession); // Debugging
  //       // console.log(url); // Debugging

  //       let parsedSession = null

  //       // Attempt to parse shopifySession, handle invalid JSON
  //       try {
  //         parsedSession = JSON.parse(shopifySession) // Parse session data
  //       } catch (error) {
  //         console.error('Error parsing shopifySession:', error)
  //         return // Exit early if session data is invalid
  //       }

  //       const data = {
  //         name: url.replace('.myshopify.com', ''),
  //         type: 'shopify',
  //         user_id: JSON.parse(sessionStorage.getItem('user')).id,
  //         meta: parsedSession
  //       }

  //       const res = await postDataToStore(data) // Await the async post data request

  //       if (!isTrimmed) {
  //         setUrl(url) // Strip URL if needed
  //         setIsTrimmed(true) // Mark URL as trimmed
  //       }

  //       if (res.success) {
  //         console.log(res.data)
  //         setDisable(true) // Disable some UI element (for example)
  //         sessionStorage.setItem('store_id', res.data.store_id) // Save store ID in session
  //         GetUrlData() // Function call to get the URL data
  //         localStorage.removeItem('shopifySession') // Clean up session data from localStorage
  //       }
  //     }
  //   }

  //   processShopifySession() // Call the async function
  // }, [shopifySession, url]) // Effect depends on shopifySession and url

  const handleSubmit = async e => {
    e.preventDefault()

    // Trim and validate the URL
    const trimmedUrl = url.trim()
    if (!trimmedUrl) {
      toast.error('Enter business URL.', {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
      return
    }

    // Validate Shopify URL format
    if (!validateShopifyUrl(trimmedUrl)) {
      toast.error('Enter a valid Shopify URL.', {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
      return
    }

    console.log(trimmedUrl)

    localStorage.setItem('url', url)

    const authUrl = trimmedUrl.replace('https://', '').replace(/\/$/, '')
    const authRes = await authShopify(authUrl)

    // console.log(authRes)
    window.location.href = authRes

    // window.open(authRes, '_blank', 'width=600,height=600')
  }

  function validateShopifyUrl (url) {
    const regex = /^https:\/\/[\w-]+\.myshopify\.com\/?$/
    return regex.test(url)
  }

  // const handleSubmit = (e) => {
  //     e.preventDefault();

  //     if (!url.trim()) {
  //         toast.error('Enter business URL.', {
  //             position: "bottom-center",
  //             autoClose: 3000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //         });
  //     } else {
  //         const data = {
  //             name: url.replace('.myshopify.com', ''),
  //             type: "shopify",
  //             user_id: JSON.parse(sessionStorage.getItem('user')).id,
  //             meta: {
  //                 "session": {
  //                     "id": "offline_steppingedge-theme-prototype-1.myshopify.com",
  //                     "shop": "steppingedge-theme-prototype-1.myshopify.com",
  //                     "state": "444645635967517",
  //                     "isOnline": false,
  //                     "scope": "read_products,read_orders,read_content",
  //                     "accessToken": "shpua_849e896055020be6f1f8fbb4f8b497e2"
  //                 }
  //             }
  //         };

  //         postDataToStore(data)
  //             .then(response => {
  //                 if (!isTrimmed) {
  //                     const strippedUrl = url
  //                     setUrl(strippedUrl);
  //                     setIsTrimmed(true);
  //                 }

  //                 if (response.success === true) {
  //                     toast.success(response.message, {
  //                         position: "bottom-center",
  //                         autoClose: 3000,
  //                         hideProgressBar: true,
  //                         closeOnClick: true,
  //                         pauseOnHover: true,
  //                         draggable: true,
  //                     });
  //                     setDisable(true);
  //                     sessionStorage.setItem("store_id", response.data.store_id);
  //                     GetUrlData();
  //                 } else {
  //                     toast.error(response.message, {
  //                         position: "bottom-center",
  //                         autoClose: 3000,
  //                         hideProgressBar: true,
  //                         closeOnClick: true,
  //                         pauseOnHover: true,
  //                         draggable: true,
  //                     });
  //                 }
  //             })
  //             .catch(error => {
  //                 toast.error(error.message || "An error occurred.", {
  //                     position: "bottom-center",
  //                     autoClose: 3000,
  //                     hideProgressBar: true,
  //                     closeOnClick: true,
  //                     pauseOnHover: true,
  //                     draggable: true,
  //                 });
  //             });
  //     }
  // };

  const handleBackClick = () => {
    if (loading) {
      setShowDialog(true)
    } else {
      handleback()
    }
  }

  const handlecancel = () => {
    handleback()
  }

  const closeDialog = () => {
    setShowDialog(false) // Hide the dialog
    setshowitemdataset(false)
    SetEditStoreInfoOpen(false)
  }

  const viewstoreinformation = () => {
    SetEditStoreInfoOpen(true)
    console.log(summarizeData)
  }

  const handleTextareaChange = e => {
    SetsummarizeData(e.target.value) // Update state on user input
  }

  const updateStoreInfo = inputcontent => {
    console.log(isToggled);
    setsummarizeContent(inputcontent)
    SetEditStoreInfoOpen(false)
  }

  const trainSummarizeData = async () =>{
    console.log(summarizecontent)
    // Wrap the data inside an array
    const data = [
      {
        title: summarizetitle,
        type: 'article',
        status: 'inactive',
        store_id: storeId,
        meta: {
          data: {
            title: summarizetitle,
            content: summarizecontent
          }
        },
        training_processing: false
      }
    ]
    if(!isToggled){
      return
    }
    // Assuming `insertdataset` expects an array of datasets
    await insertdataset(data)
      .then(async res => {
        if (res.success) {
          if (res.data[0].status === 'inactive') {
            // console.log(res.data[0].dataset_id)
            const datasetid = res.data[0].dataset_id
            await new GetDataSet().setTrained([datasetid])
          }
          const deleteEvent = new CustomEvent('datasetDeleted', {
            detail: true
          })
          window.dispatchEvent(deleteEvent)
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  useEffect(()=>{
  },[summarizecontent]);

  return (
    <div>
      {EditStoreInfoOpen && (
        <div className='fixed inset-0 bg-black bg-opacity-25  p-5 flex justify-center items-center z-50'>
          <div className='bg-[#FFFFFF] p-5 sm:p-7 shadow-lg w-full max-w-[900px] mx-auto my-[30px]   rounded-[24px] border-[1.6px] border-white overflow-y-auto min-h-[40%]  max-h-[90%] scrollbar-hide '>
            <div className='block'>
              <div className='flex justify-between'>
                <h1 className='font-extrabold text-[25px] mb-10'>
                  Update Your Store Information
                </h1>
                {/* <button className="  text-black text-[13px] absolute right-9 font-semibold  rounded-[24px]" onClick={() => setformDialogopen(false)}>X</button> */}
                {/* <svg
                  width='20'
                  height='20'
                  className='cursor-pointer'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  onClick={() => {
                    setformDialogopen(false)
                    setupdateDialogopen(false)
                  }}
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M9.10041 7.77459C8.7343 7.40847 8.1407 7.40847 7.77459 7.77459C7.40847 8.1407 7.40847 8.7343 7.77459 9.10041L13.6742 15L7.77459 20.8996C7.40847 21.2657 7.40847 21.8593 7.77459 22.2254C8.1407 22.5915 8.7343 22.5915 9.10041 22.2254L15 16.3258L20.8996 22.2254C21.2657 22.5915 21.8593 22.5915 22.2254 22.2254C22.5915 21.8593 22.5915 21.2657 22.2254 20.8996L16.3258 15L22.2254 9.10041C22.5915 8.7343 22.5915 8.1407 22.2254 7.77459C21.8593 7.40847 21.2657 7.40847 20.8996 7.77459L15 13.6742L9.10041 7.77459Z'
                    fill='#373737'
                  />
                </svg> */}
                <button
                  class='w-[30px]  sm:w-[40px] h-[30px] sm:h-[40px] text-[14px] sm:text-[18px] cursor-pointer'
                  onClick={closeDialog}
                >
                  <svg
                    width='30'
                    height='30'
                    viewBox='0 0 30 30'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M9.10041 7.77459C8.7343 7.40847 8.1407 7.40847 7.77459 7.77459C7.40847 8.1407 7.40847 8.7343 7.77459 9.10041L13.6742 15L7.77459 20.8996C7.40847 21.2657 7.40847 21.8593 7.77459 22.2254C8.1407 22.5915 8.7343 22.5915 9.10041 22.2254L15 16.3258L20.8996 22.2254C21.2657 22.5915 21.8593 22.5915 22.2254 22.2254C22.5915 21.8593 22.5915 21.2657 22.2254 20.8996L16.3258 15L22.2254 9.10041C22.5915 8.7343 22.5915 8.1407 22.2254 7.77459C21.8593 7.40847 21.2657 7.40847 20.8996 7.77459L15 13.6742L9.10041 7.77459Z'
                      fill='#373737'
                    ></path>
                  </svg>
                </button>
              </div>
              <form>
                <div className='w-full mt-2'>
                  <input
                    type='text'
                    name='title'
                    className='bg-[#F8F7F7] focus:outline-none w-full h-[55px] rounded-[24px] p-5  '
                    placeholder='Title'
                    value={'Store Information'} // Bind value to state
                    readOnly
                  />
                </div>
                <div className='w-full mt-3'>
                  {/* <input type="te" className="bg-[#F8F7F7] focus:outline-none w-full h-[180px] rounded-[24px] p-5  " placeholder="Title" /> */}
                  <textarea
                    name='content'
                    maxLength={2000}
                    cols='30'
                    rows='10'
                    placeholder='Enter the content'
                    className='bg-[#F8F7F7] focus:outline-none w-full h-[180px] rounded-[24px] p-5  '
                    value={summarizeData} // Bind value to state
                    onChange={handleTextareaChange} // Handle changes
                    required
                  ></textarea>
                </div>
                <small className='ml-2'>*Max.word limit 2000</small>

                <div className='flex justify-end mt-5'>
                  {/* <button className="w-[162px] border-[1px] border-black text-[14px] font-medium flex justify-center items-center p-2 mr-2 rounded-[19px]">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.5C12.4142 4.5 12.75 4.83579 12.75 5.25V11.25H18.75C19.1642 11.25 19.5 11.5858 19.5 12C19.5 12.4142 19.1642 12.75 18.75 12.75H12.75V18.75C12.75 19.1642 12.4142 19.5 12 19.5C11.5858 19.5 11.25 19.1642 11.25 18.75V12.75H5.25C4.83579 12.75 4.5 12.4142 4.5 12C4.5 11.5858 4.83579 11.25 5.25 11.25H11.25V5.25C11.25 4.83579 11.5858 4.5 12 4.5Z" fill="#252530" />
                                        </svg>

                                        Create Question
                                    </button> */}

                  <button
                    onClick={() => {
                      updateStoreInfo(summarizeData)
                    }}
                    type='button'
                    className='bg-[#7C50DD] text-white py-2 px-10 text-[14px]  rounded-[19px]'
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className='flex items-start justify-between'>
        <div className='block'>
          <div className='flex items-start mb-1 justify-start'>
            <img src={shopifybag} alt='' />
            <h1 className='text-[#252530] ml-3 font-extrabold text-[23px] leading-[31px]'>
              Shopify
            </h1>
          </div>
          <p className='text-[12px] ml-1'>
            Train Your Chatbot to Enhance Customer Interactions on Any Platform
          </p>
        </div>
        <button
          className='border border-[#7C50DD] rounded-[39px] flex items-center justify-start text-[14px] font-normal p-[10px] h-[44px] bg-white w-[103px]'
          aria-label='Go back'
          onClick={handleBackClick}
        >
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M16.1705 4.4545C16.6098 4.89384 16.6098 5.60616 16.1705 6.0455L10.216 12L16.1705 17.9545C16.6098 18.3938 16.6098 19.1062 16.1705 19.5455C15.7312 19.9848 15.0188 19.9848 14.5795 19.5455L7.8295 12.7955C7.39017 12.3562 7.39017 11.6438 7.8295 11.2045L14.5795 4.4545C15.0188 4.01517 15.7312 4.01517 16.1705 4.4545Z'
              fill='#7C50DD'
            />
          </svg>
          <span className='ml-2'>Back</span>
        </button>
      </div>
      <div className='mt-2 relative flex w-full'>
        <form className='relative w-full sm:block'>
          {/* <input
            value={url} // Input reflects the trimmed URL
            onChange={e => {
              setUrl(e.target.value)
              setIsTrimmed(false) // Reset isTrimmed when URL is manually changed
            }}
            type='text'
            className={`focus:outline-none w-full h-[56px] rounded-[39px] pl-[40px] sm:pr-[100px] pr-[70px] pt-[10px] pb-[10px] placeholder:text-[15px] placeholder:text-[#252530] font-normal ${
              disable && 'cursor-not-allowed'
            }`}
            placeholder='Enter a URL address'
            readOnly={disable}
          /> */}
          <a href='https://apps.shopify.com/magbot'>
            <button
              type='button'
              className={`mt-3 bg-[#7C50DD] cursor-pointer sm:mt-0 sm:relative s flex items-center text-[12px] h-[36px] border-[1px] p-[20px] 
            text-white w-full sm:w-auto justify-center font-medium rounded-[20px]`}
            >
              Install MagBot App
            </button>
          </a>
        </form>

        {showDialog && (
          <div className='fixed inset-0 flex   items-center justify-center bg-black bg-opacity-50 z-50 p-5'>
            <div
              className='bg-[#F4F4F4] p-5 sm:p-7 shadow-lg  max-w-xs min-w-[370px] sm:min-w-[400px] min-h-[240px] rounded-[24px] border-[1.6px] border-white'
              style={{
                boxShadow: 'box-shadow: -1px 0px 0px 0px #DFE5F1 inset;'
              }}
            >
              <div className='flex justify-end items-start cursor-pointer'>
                <div className='w-[10px] h-[10px] '>
                  <p className='text-[#373737] text-sm font-semibold'>X</p>
                </div>
              </div>
              <div className='block mt-7 flex flex-col items-center'>
                <div className='flex flex-col items-center text-left'>
                  <h1 className='font-extrabold text-[28px] leading-[38.25px]'>
                    Training isn’t finished yet
                  </h1>
                  <h6 className='ml-12 text-[14px] mt-1 leading-4'>
                    Are you sure you want to go back? You’ll lose the generated
                    content
                  </h6>
                </div>
                {/* Buttons aligned to the end */}
                <div className='flex justify-end space-x-4 mt-10 ml-28'>
                  <button
                    onClick={handlecancel}
                    className='px-4 py-2 w-[85px] h-[39px]  bg-[#232328] border-[#0F062CB2] text-white font-semibold text-[14px] rounded-[59px] p-[10px] '
                  >
                    Back
                  </button>
                  <button
                    onClick={closeDialog}
                    className='px-4 py-2 w-[139px] h-[39px] border-[1px] p-[10px] rounded-[39px] gap-[5px] bg-[#7C50DD] border-[#7C50DD] font-semibold text-[14px] leading-[19.12px] text-white'
                  >
                    Finish training
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showitemdataset && (
          <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-5'>
            <div
              className='bg-[#FFFFFF] px-5 pb-3 shadow-lg w-full max-w-[700px] mx-auto my-[30px]   rounded-[24px] border-[1.6px] border-white overflow-y-auto  max-h-[80%] scroll-m-16 scrollbar-custom1'
              style={{
                boxShadow: 'box-shadow: -1px 0px 0px 0px #DFE5F1 inset;'
              }}
            >
              <div className='block'>
                <div className='flex bg-white justify-end sticky top-0 px-2 py-3 z-50'>
                  {/* <div className='block'>
                                            <h1 className='font-extrabold text-[20px] sm:text-[25px] leading-[30px] sm:leading-[34.15px]'>
                                                Train the bot with pages from the “Product” category
                                            </h1>
                                            <span className='font-[400] text-[11px] sm:text-[13px]'>
                                                There are 6 pages in the General category. Choose which should be used to train your AI model.
                                            </span>
                                        </div> */}
                  <button
                    onClick={closeDialog}
                    className='w-[30px]  sm:w-[40px] h-[30px] sm:h-[40px] text-[14px] sm:text-[18px] cursor-pointer'
                  >
                    <svg
                      width='30'
                      height='30'
                      viewBox='0 0 30 30'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M9.10041 7.77459C8.7343 7.40847 8.1407 7.40847 7.77459 7.77459C7.40847 8.1407 7.40847 8.7343 7.77459 9.10041L13.6742 15L7.77459 20.8996C7.40847 21.2657 7.40847 21.8593 7.77459 22.2254C8.1407 22.5915 8.7343 22.5915 9.10041 22.2254L15 16.3258L20.8996 22.2254C21.2657 22.5915 21.8593 22.5915 22.2254 22.2254C22.5915 21.8593 22.5915 21.2657 22.2254 20.8996L16.3258 15L22.2254 9.10041C22.5915 8.7343 22.5915 8.1407 22.2254 7.77459C21.8593 7.40847 21.2657 7.40847 20.8996 7.77459L15 13.6742L9.10041 7.77459Z'
                        fill='#373737'
                      />
                    </svg>
                  </button>
                </div>
                {/* Search Input */}
                <div className='mt-3'>
                  <input
                    type='text'
                    placeholder='Search items...'
                    className='outline-none w-full p-2 rounded-[24px] border border-gray-300 mb-4'
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className='mt-3'>
                  {Array.isArray(FilteredData) && FilteredData.length > 0 ? (
                    FilteredData.map((item, index) => (
                      <div
                        key={index}
                        className='w-full p-4 sm:p-5 h-[54px] mt-2 flex justify-between items-center rounded-[84px] border-[0.5px] bg-[#F8F7F7]'
                      >
                        <h2 className='text-sm sm:text-base'>{item.title}</h2>
                        <div
                          onClick={() => childtoggleStatus(index)}
                          className={`h-[22px] w-[46px] mr-2 rounded-[20px] cursor-pointer flex items-center ${
                            item.status === 'inactive'
                              ? 'bg-[#D9D9D980]'
                              : 'bg-[#37BD4A]'
                          }`}
                        >
                          <div
                            className={`h-[18px] w-[18px] rounded-full transition-transform ${
                              item.status === 'inactive'
                                ? 'translate-x-[2px] bg-white'
                                : 'translate-x-[24px] bg-white'
                            }`}
                          ></div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className='text-center text-gray-500'>No items found.</p>
                  )}
                </div>
                <div className='flex justify-end mt-8  mr-3 '>
                  <button
                    onClick={closeDialog}
                    className='bg-[#7C50DD] rounded-[39px] text-white w-[139px] h-[39px]'
                  >
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showbotloader && (
          <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50'>
            <div class='containerbot'>
              <div id='chatbot'>
                <div class='dot'></div>
                <div class='dot'></div>
                <div class='dot'></div>
              </div>
              <div id='chatbot-corner'></div>
              <div id='antenna'>
                <div id='beam'></div>
                <div id='beam-pulsar'></div>
              </div>
              <p className='absolute top-[65%] left-[30%] text-white font-extrabold '>
                Processing a training dataset
              </p>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
      {/* <p className='text-[12px] mt-5 ml-1 text-[#565656]'>
        Example: https://store-name.myshopify.com
      </p> */}
      <p className='text-[12px] mt-2 ml-1 text-[#565656]'>
        By sharing your URL, you confirm you have the necessary rights to share
        its content.
      </p>

      {EditStoreInfoCategory && (
        <div className='mt-4 grid grid-cols-2 gap-4'>
          <div className='bg-[#F8F7F7] sm:h-[80px] h-auto p-5 flex flex-col sm:flex-row items-center justify-between rounded-[34px] md:rounded-[84px]'>
            {/* Left Section: Title */}
            <div className='w-full sm:w-auto flex flex-col justify-center'>
              <div className='mb-5 bg-opacity-5 h-[17px] rounded-[20px] w-full'>
                <p className='font-bold text-[17px]'>Store Information</p>
                <p className=' text-[11px]'>Knowledge Base</p>
              </div>
            </div>

            {/* Right Section: Toggle and View Button */}
            <div className='flex items-center justify-between sm:justify-end w-full sm:w-auto mt-3 sm:mt-0'>
              <div className='flex items-center justify-between sm:justify-end w-full'>
                {/* Toggle Button */}
                <div
                  onClick={toggleStatusStoreInformation}
                  className={`h-[22px] w-[46px] mr-2 rounded-[20px] cursor-pointer flex items-center ${
                    isToggled ? 'bg-[#37BD4A]' : 'bg-[#D9D9D980]'
                  }`}
                >
                  <div
                    className={`h-[18px] w-[18px] rounded-full transition-transform ${
                      isToggled ? 'translate-x-[24px]' : 'translate-x-[2px]'
                    } bg-white`}
                  ></div>
                </div>

                {/* View Button */}
                <button
                  onClick={viewstoreinformation}
                  className='w-[81px] h-[27px] text-[#FFFFFF] rounded-[48px] font-[500] text-[10.34px] leading-[12px] bg-[#7C50DD]'
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 overflow-auto'>
        {showcategory &&
          (loading
            ? Array.from({ length: 5 }).map(
                (_, index) => <CategorySkeleton key={index} />
              )
            : listcategory.map((item, index) => (
                <div
                  key={item.name}
                  className='bg-[#F8F7F7] sm:h-[80px] h-auto p-5 flex flex-col sm:flex-row items-center justify-between rounded-[34px] md:rounded-[84px]'
                >
                  <div className='block w-full sm:w-auto'>
                    <div className='mb-2 bg-opacity-5 h-[17px] rounded-[20px] w-full '>
                      <p className='font-bold text-[17px]'>{item.name}</p>{' '}
                      {/* Render the name of the item */}
                    </div>
                    <div
                      onClick={() => viewpages(index)}
                      className='cursor-pointer bg-opacity-5 h-[14px] flex justify-start space-x-1 gap-2'
                    >
                      <div className='flex'>
                        <p className='text-[11px]'>{item.activeCount}/</p>
                        <p className='text-[11px] space-x-2'>
                          {Array.isArray(item.data) && item.data
                            ? item.data.length
                            : 0}{' '}
                          <span>pages</span>
                        </p>
                        <svg
                          width='13'
                          height='13'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.85453 13.3333H13.7454M10.8 2.33334C11.0604 2.06813 11.4136 1.91913 11.7818 1.91913C11.9641 1.91913 12.1447 1.95571 12.3132 2.02678C12.4816 2.09785 12.6347 2.20202 12.7636 2.33334C12.8926 2.46466 12.9948 2.62057 13.0646 2.79215C13.1344 2.96373 13.1703 3.14762 13.1703 3.33334C13.1703 3.51906 13.1344 3.70296 13.0646 3.87454C12.9948 4.04612 12.8926 4.20202 12.7636 4.33334L4.5818 12.6667L1.96362 13.3333L2.61817 10.6667L10.8 2.33334Z'
                            stroke='#252530'
                            strokeWidth='1.6'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className='flex items-center justify-between sm:justify-end w-full sm:w-auto mt-3 sm:mt-0'>
                    <div className='flex items-center justify-between sm:justify-end w-full'>
                      <div
                        onClick={() => toggleStatus(index)}
                        className={`h-[22px] w-[46px] mr-2 rounded-[20px] cursor-pointer flex items-center ${
                          item.status === 'active'
                            ? 'bg-[#37BD4A]'
                            : 'bg-[#D9D9D980]'
                        }`}
                      >
                        <div
                          className={`h-[18px] w-[18px] rounded-full transition-transform ${
                            item.status === 'active'
                              ? 'translate-x-[24px] bg-white'
                              : 'translate-x-[2px] bg-white'
                          }`}
                        ></div>
                      </div>
                      <button
                        onClick={() => viewpages(index)}
                        className='w-[81px] h-[27px] text-[#FFFFFF] rounded-[48px] font-[500] text-[10.34px] leading-[12px] bg-[#7C50DD]'
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              )))}
      </div>

      {showcategory  && !loading && EditStoreInfoCategory && (
        <div className='flex justify-end mt-5 '>
          <button
            onClick={()=>{
              handlepassdata()
              trainSummarizeData()
            }}
            className='bg-[#7C50DD] border-[1px] border-[#7C50DD] w-[166px] h-[48px] rounded-[59px] p-[10px] text-[14px] leading-[19.12px] font-[700] text-white'
          >
            Next
          </button>
        </div>
      )}
    </div>
  )
}

export default GetShopifyURl
