// src/routes.js
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import ForgotPassword from './pages/ForgotPassword/forgot-password';
import ResetPassword from './pages/ForgotPassword/reset-password';
import AddChatBot from './pages/Home';
import Training from './pages/Training';
import BotCustomization from './pages/BotCustom';
import ShopifyCallback from './util/shopifycallback';
import Chats from './pages/Chats';
import Analytics from './pages/Analytics';
import TeamsPage from './pages/terms';
import Privacy from './pages/privacy';
import FeedBack from './pages/Feedback';
import TicketManagement from './pages/TicketManagement';

// Define routes in an array
const routes = [
    {
        path: '/',
        element: <LoginPage />,
    },
    {
        path: '/register',
        element: <RegisterPage />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
    },
    {
        path: '/home',
        element: <AddChatBot />,
    },
    {
        path: '/training',
        element: <Training />,
    },
    {
        path: '/bot-customization',
        element: <BotCustomization/>,
    },
    {
        path: '/ticket-management',
        element: <TicketManagement/>,
    },
    {
        path: '/shopify/callback',
        element: <ShopifyCallback />,
    },
    {
        path: '/chats',
        element: <Chats />,
    },
    {
        path:'/analytics',
        element: <Analytics/>
    },
    {
        path:'/feedback',
        element: <FeedBack/>
    },
    {
        path:'/subscription',
        element: <Analytics/>
    },
    {
        path:'/pages/terms-conditions',
        element: <TeamsPage/>
    },
    {
        path:'/pages/privacy-policy',
        element: <Privacy/>
    }
];

export default routes;
