import { FaStar } from 'react-icons/fa';
import { useState, useEffect } from 'react';

const StartRate = ({
  starSize = 20,
  activeColor = '#ede839',
  inactiveColor = 'gray',
  onRatingChange,
}) => {
  const [currentRate, setCurrentRate] = useState(null);
  const [hoverRate, setHoverRate] = useState(null);

  const calculateRating = (rating) => (rating * 10) / 5;

  useEffect(() => {
    const handlefeedbackSubmitted = event => {
      const check  = event.detail
      if (check) {
        setCurrentRate(null)
        setHoverRate(null)
      }
    }

    window.addEventListener('feedbackSubmitted', handlefeedbackSubmitted) // Add event listener for paste event
    return () => {
      window.removeEventListener('feedbackSubmitted', handlefeedbackSubmitted) // Remove event listener on component unmount
    }
  }, [hoverRate,currentRate])  

  return (
    <div className="flex space-x-1">
      {[...Array(5)].map((_, index) => {
        const starCount = index + 1;

        return (
          <label key={index} className="cursor-pointer">
            <input
              type="radio"
              style={{ display: 'none' }}
              name="rate"
              value={starCount}
              onClick={() => {
                const calculatedRating = calculateRating(starCount);
                setCurrentRate(starCount);
                if (onRatingChange) onRatingChange(calculatedRating);
              }}
            />
            <FaStar
              size={starSize}
              color={
                starCount <= (hoverRate || currentRate) ? activeColor : inactiveColor
              }
              onMouseEnter={() => setHoverRate(starCount)}
              onMouseLeave={() => setHoverRate(null)}
            />
          </label>
        );
      })}
    </div>
  );
};

export default StartRate;
