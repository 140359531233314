import React from "react";
import Site from "./site";
import Article from "./article";
import Faq from "./faq";
import Unmatched from "./unmatched";

const MainContent = ({ active }) => {
  let content;
  switch (active) {
    case 1:
      content = <Site />;
      break;
    case 2:
      content = <Article/>;
      break;
      case 3:
        content = <Faq/>;
        break;
    default:
      content = <Unmatched/>
  }

  return (
    <div className="flex-1 h-auto bg-white m-5 rounded-[16px]">
      {content}
    </div>
  );
};

export default MainContent;
