import React, { useState, useEffect } from 'react'
import StartRate from '../../components/rating'
import ImageDragDrop from '../../components/multipleimageupload'
import 'react-toastify/dist/ReactToastify.css' // Import the styles for the toast notifications
import { ToastContainer, toast } from 'react-toastify'
import { feedback } from '../../services/data/index.js'

const FeedBack = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    rating: '',
    suggestion: '',
    attachments: []
  })

  const [error, setError] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState([])

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  const handleFilesChange = newFiles => {
    setUploadedFiles(newFiles) // Update the state with the new files
  }

  //   useEffect(() => {
  //     console.log('Uploaded Files:', uploadedFiles);
  //   }, [uploadedFiles]);

  const handleRatingChange = calculatedRating => {
    setFormData(prevData => ({ ...prevData, rating: calculatedRating }))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    // Prepare the feedback data in the required format
    const feedbackData = {
      store_id: sessionStorage.getItem('store_id'), 
      Name: formData.name,
      Contact_Email: formData.email,
      Rating: formData.rating.toString(), 
      Suggestion: formData.suggestion,
      Images: uploadedFiles.map(file => file.url) // Map uploaded files to their URLs
    }

    try {
      // Call the feedback function to submit the data
      const response = await feedback(feedbackData)
      //   console.log("Feedback submitted:", response);
      if (response.success) {
        toast.success(response.message)
        // Clear the form data and uploaded files after successful submission
        setFormData({
          name: '',
          email: '',
          rating: '',
          suggestion: '',
          attachments: []
        })
        setUploadedFiles([]) // Clear the uploaded files array
        window.dispatchEvent(new CustomEvent('feedbackSubmitted',{detail:true}))
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <div className='flex items-center justify-center min-h-screen bg-transparent p-6'>
      <div className='bg-white p-8 rounded-[16px] shadow-md w-full max-w-lg'>
        <h1 className='text-[23px] font-extrabold '>Feedback</h1>
        <p class="font-medium mt-1 text-[#252530] text-[12px] ">Share Your Feedback – Your Insights Help Us Enhance Your Experience!</p>
        <form onSubmit={handleSubmit} className='space-y-4 mt-6'>
          <div>
            <label htmlFor='name' className='block text-sm mb-2  font-medium'>
              Name:
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              required
              className='w-full px-4 py-2 border rounded-lg focus:outline-none'
            />
          </div>
          <div>
            <label htmlFor='email' className='block mb-2  text-sm font-medium'>
              Contact Email:
            </label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
              required
              className='w-full px-4 py-2 border rounded-lg focus:outline-none'
            />
          </div>
          <div>
            <label htmlFor='rating' className='block text-sm font-medium mb-2 '>
              Rating:
            </label>
            <StartRate onRatingChange={handleRatingChange} />
          </div>
          <div>
            <label htmlFor='suggestion' className='block text-sm font-medium mb-2 '>
              Suggestion:
            </label>
            <textarea
              id='suggestion'
              name='suggestion'
              value={formData.suggestion}
              onChange={handleInputChange}
              required
              className='w-full px-4 py-2 border rounded-lg focus:outline-none'
              rows='4'
              maxLength={255}
            ></textarea>
          </div>
          <div>
            <label htmlFor='attachments' className='block text-sm font-medium mb-2 '>
              Attachment (Max 2MB each):
            </label>
            <ImageDragDrop onFilesChange={handleFilesChange} />
            {error && <p className='text-red-500 text-sm mt-1'>{error}</p>}
          </div>
          <button
            type='submit'
            className='w-full bg-[#232328]  rounded-[59px] text-white py-2 px-4  transition'
          >
            Submit
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default FeedBack
