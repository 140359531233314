import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Load the relativeTime plugin
dayjs.extend(relativeTime);

const DateTimeFormat = (dateString) => {
    const date = dayjs(dateString);
    const now = dayjs();
    const time = date.format('h:mm A'); // 12-hour time format with AM/PM


    let formattedDate;

    if (date.isSame(now, 'day')) {
        formattedDate = "Today";
    } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
        formattedDate = "Yesterday";
    } else if (now.diff(date, 'day') < 7) {
        // If it's within the past week
        formattedDate = date.fromNow(); // e.g., "3 days ago"
    } else {
        // Otherwise, return exact date format (e.g., "27 Sep 2024")
        formattedDate = date.format('DD MMM YYYY');
    }

    return {
        date: formattedDate,
        time: time,
    };
}

// Example usage
// console.log(DateTimeFormat("2024-11-08T07:35:44.000Z").date);
// console.log(DateTimeFormat("2024-11-08T07:35:44.000Z").time);
export default DateTimeFormat;