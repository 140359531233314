import React, { useState } from 'react'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../assets/chatbotlogo.png'
import bannerimage from '../../assets/bannerimage.png'
import { Link, Navigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { registerUser } from '../../services/authentication/register-service'
import { registrationverification } from '../../services/data'
import { set } from 'date-fns'

const Register = () => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [visibleconfirmpassword, setvisibleconfirmpassword] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [verificationid, setverificationid] = useState('')

  // State to hold form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    verification_id: '',
    verification_code: '',
    confirmPassword: ''
  })

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const togglePasswordVisibility1 = () => {
    setvisibleconfirmpassword(!visibleconfirmpassword)
  }

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!formData.name || !formData.email || !formData.password) {
      toast.error('Please fill in all fields.', {
        position: 'bottom-center'
      })
      return
    }

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match.', {
        position: 'bottom-center'
      })
      return
    }

    if (!termsAccepted) {
      toast.error('Please accept the Terms and Privacy policy to continue.', {
        position: 'bottom-center'
      })
      return
    }
    // const { confirmPassword, ...dataToSend } = formData
    // console.log(dataToSend)

    var data = {
      email: formData.email,
      name: formData.name,
      password: formData.password,
      verification_id: verificationid,
      verification_code: e.target.verification_code.value
    }
    console.log(data)
    try {
      setLoading(true) // Set loading state to true
      //   const { confirmPassword, ...dataToSend } = formData
      // eslint-disable-next-line
      const result = await registerUser(data) // Call registerUser with filtered data
      toast.success('Account created successfully!', {
        position: 'bottom-center'
      })
      setRegistrationSuccess(true) // Set success state to true
      localStorage.setItem('registrationData', JSON.stringify(result));

    //   window.dispatchEvent(new CustomEvent('registrationSuccess',{detail:{
    //     message:data.message
    //   }}))
    } catch (error) {
      //   toast.error(error.message, {
      //     position: 'bottom-center'
      //   })
    } finally {
      setLoading(false) // Reset loading state
    }
  }

  // Redirect to another page after successful registration
  if (registrationSuccess) {    
    
    return <Navigate to='/'  />
  }

  const requestvericationcode = async () => {
    console.log(formData.name)
    var data = {
      email: formData.email,
      name: formData.name
    }
    console.log(data)
    if (formData.name === '' || formData.email === '') {
      toast.error('Please fill in all fields.', {
        position: 'bottom-center'
      })
    } else {
      const res = await registrationverification(data)
      console.log(res)
      if (res.success) {
        toast.success(res.message, {
          position: 'bottom-center'
        })
        setverificationid(res.data.verification_id)
      }
    }
  }

  return (
    <section>
      <ToastContainer />
      <div className=' md:h-[100vh] w-full  grid grid-rows-2 md:grid-cols-2'>
        {/* Left Container */}
        <div className='w-full h-full register-description centered flex flex-col justify-start items-center p-10 4xl:pl-[20%]  4xl:pr-[20%] 3xl:pl-[20%] 3xl:pr-[20%] md:min-h-screen md:overflow-y-hidden'>
          <h1 className='text-center font-[600] text-[26px] leading-[25px] pl-13 pr-13 w-[90%] md:w-[70%] pb-3 text-white'>
            Scale Your eCommerce Growth
          </h1>
          <p className='font-[400] text-[14px] mb-5 4xl:text-[19px] 3xl:text-[19px] text-white text-center'>
            Welcome to{' '}
            <span className='text-[#FFF] underline'>
              {' '}
              <a href='https://magbot.ai/'>MagBot</a>
            </span>{' '}
            – your smart, AI-powered assistant designed to optimize online store
            performance. From personalized product recommendations to seamless
            order tracking and automated cart recovery,{' '}
            <span className='text-[#FFF] underline'>
              <a href='https://magbot.ai/'>MagBot</a>
            </span>{' '}
            takes your eCommerce experience to the next level.
          </p>
          {/* <div className='flex justify-center items-center w-full h-auto'>
                    <img src={Bannerimg} alt="Banner" className='w-auto max-h-[290px] md:max-h-[100%]' />
                </div> */}
        </div>

        {/* Right Form Container */}
        <div className='flex justify-center items-center p-10 md:min-h-screen md:overflow-y-auto'>
          <div className='w-full max-w-lg md:w-[90%] md:pt-[45%] mt-10 md:pl-[13%] md:pr-[13%] lg:pt-[2%] lg:pl-[9%] lg:pr-[9%]'>
            <div className='flex justify-center md:justify-center mb-2'>
              <img src={Logo} alt='Logo' className='w-auto h-[55px]' />
              {/* <h1 className='text-black font-bold text-[1.5rem] ml-2'>MagBot</h1> */}
            </div>

            <form className='w-full' onSubmit={handleSubmit}>
              <div className='block mb-4 text-center'>
                <h2 className='font-[600] text-[20px] 2xl:text-[19px] 3xl:text-[18px] 4xl:text-[18px] leading-[24px] text-[#000]'>
                  Create account
                </h2>
                <p className='font-[400] text-[14px] mt-4'>
                  Start Your New Age Chat Technology
                </p>
              </div>

              {/* Full Name Field */}
              <div className='block mb-4'>
                <p className='text-[#2D3748] text-[14px] mb-1 leading-[14px]'>
                  Full name
                </p>
                <input
                  type='text'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  className='w-full p-2 text-[12px] h-[38px] rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none'
                />
              </div>

              {/* Email Field */}
              <div className='block mb-4'>
                <p className='text-[#2D3748] text-[14px] leading-[14px] mb-1'>
                  Email
                </p>
                <input
                  type='email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  className='w-full p-2 text-[12px] h-[38px] rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none'
                />
              </div>

              {/* Password Field */}
              <div className='block mb-1'>
                <p className='text-[#2D3748] text-[14px] leading-[14px] mb-1'>
                  Password
                </p>
                <div className='relative'>
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    name='password'
                    value={formData.password}
                    onChange={handleChange}
                    className='w-full p-2 h-[38px] text-[12px] rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none'
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className='absolute right-3 top-[50%] text-[12px] transform -translate-y-1/2 text-[#d4d4d4] cursor-pointer focus:outline-none'
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </span>
                </div>
              </div>

              {/* Confirm Password Field */}
              <div className='block mb-1 mt-4'>
                <p className='text-[#2D3748] text-[14px] leading-[14px] mb-1'>
                  Confirm password
                </p>
                <div className='relative'>
                  <input
                    type={visibleconfirmpassword ? 'text' : 'password'}
                    name='confirmPassword'
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className='w-full h-[38px] p-2 text-[12px] rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none'
                  />
                  <span
                    onClick={togglePasswordVisibility1}
                    className='absolute right-3 top-[50%] text-[12px] transform -translate-y-1/2 text-[#d4d4d4] cursor-pointer'
                  >
                    <FontAwesomeIcon
                      icon={visibleconfirmpassword ? faEye : faEyeSlash}
                    />
                  </span>
                </div>
              </div>
              <div className='block mb-1 mt-4 relative'>
                <p className='text-[#2D3748] text-[14px] leading-[14px] mb-1'>
                  Verification Code
                </p>
                <div className='relative'>
                  <input
                    type='text'
                    name='verification_code'
                    value={formData.verification_code} // Bind the input value to state
                    onChange={handleChange} // Update the state on change
                    className='w-full h-[38px] p-2 text-[12px] rounded-[5px] border-[1px] border-[#D1D1D1] focus:outline-none pr-[80px]'
                  />
                  <button
                    onClick={requestvericationcode}
                    type='button'
                    className='absolute right-2 top-1/2 transform -translate-y-1/2 text-[12px] text-[#4141ef] bg-transparent px-3 py-1 rounded-[5px] cursor-pointer'
                  >
                    Send Code
                  </button>
                </div>
              </div>

              {/* Terms and Privacy policy checkbox */}
              <div className='flex items-start my-4'>
                <input
                  type='checkbox'
                  id='terms'
                  className='mr-2 mt-1 cursor-pointer focus:outline-none'
                  checked={termsAccepted}
                  onChange={handleTermsChange}
                />
                <label
                  htmlFor='terms'
                  className='text-[14px] font-[400] text-[#2D3748]'
                >
                  I agree to the{' '}
                  <Link to='/pages/terms-conditions' className='text-[#007AFF]'>
                    Terms
                  </Link>{' '}
                  and{' '}
                  <Link to='/pages/privacy-policy' className='text-[#007AFF]'>
                    Privacy policy
                  </Link>
                </label>
              </div>
              <button
                className={`bg-[#7C50DD] w-[100%] text-white h-[48px] rounded-[55px] font-semibold text-[14px] ${
                  loading || !termsAccepted
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                }`}
                type='submit'
                disabled={loading || !termsAccepted}
              >
                {loading ? 'Creating account...' : 'Create account'}
              </button>

              <div className='flex justify-center items-center mt-6'>
                <p className='text-[#4B5563] text-center text-[14px]'>
                  Already have an account?{' '}
                  <Link to='/' className='text-[#007AFF]'>
                    Log in
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Register
