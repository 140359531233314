import React, { useState, useEffect } from 'react'
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation
} from 'react-router-dom'
import routes from './route'
import { AuthProvider } from './context/logincontext'
import Navbar from './pages/layouts/navbar'
import './styles/main.css'
import useChatbotLoader from './util/chatbotloader'
import { ToastContainer } from 'react-toastify'

function AppContent () {
  const location = useLocation()
  const [jsbundle, setjsbundle] = useState('') // Manage chatbot script URL
  const [GetStoreId, setGetStoreId] = useState('') // Manage storeId state
  // Define routes where layout should be hidden
  const hideLayoutRoutes = [
    '/',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/pages/terms-conditions',
    '/pages/privacy-policy',
    '/shopify/callback'
  ]
  const hideLayout = hideLayoutRoutes.includes(location.pathname)

  useEffect(() => {
    const storeId = sessionStorage.getItem('store_id')
    console.log(storeId)
    if (storeId) {
      setGetStoreId(storeId)
      setjsbundle(process.env.REACT_APP_API_URL+'/js/loader.bundle.js')
    } else {
      setGetStoreId('')
      setjsbundle('')
    }
  }) // Run this effect only once on mount

  // Load the chatbot script when jsbundle and GetStoreId are set
  useChatbotLoader(jsbundle, GetStoreId)

  return (
    // Conditionally apply the container div for layout-specific routes
    hideLayout ? (
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    ) : (
      <div className='relative font-Dmsans max-w-[1400px] mx-auto'>
        <div className='bg-linear-gradient 3xl:mt-[1%] 4xl:w-[1%]'></div>
        <div className='main-container'>
          <Navbar />
          <div className='working-area mt-5 pb-5 md:pb-0'>
            {/* Route definitions within layout */}
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </div>
        </div>
        <ToastContainer/>
      </div>
    )
  )
}

function App () {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  )
}

export default App
