import "../../styles/analytics.css"

const Analytics = () =>{
    return(
        <div className="shine-text absolute top-1/2 left-1/2 -translate-x-1/2 text-[1.8rem] md:text-[4.5rem] -translate-y-1/4   text-gray-500">Coming Soon</div>
    )

}


export default Analytics;