import { useEffect, useState } from 'react'
import { GetChatLog } from '../../services/data'
import DateTimeFormat from '../../util/DateTimeformat'
import IonIcon from '@reacticons/ionicons'
import GetDataSet from '../../services/data'
import { insertdataset } from '../../services/data'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'

const Conversation = ({ chatlistId }) => {
  const chatLog = new GetChatLog()
  const [messages, setMessages] = useState([])
  const [sortmessage, Setsortmessage] = useState([])
  const [activeuserId, SetactiveuserId] = useState('')
  const [activeuserMessage, SetactiveuserMessage] = useState('')
  const [showpreviewMessage, SetshowpreviewMessage] = useState(false)
  const [formData, setFormData] = useState({ title: '' })

  useEffect(() => {
    const controller = new AbortController() // Create a new AbortController instance
    const { signal } = controller // Extract the signal
    const GetConversationList = async (chatlist_id, storeID) => {
      const customerChatlist = await chatLog.Get_Chat_Log(
        storeID,
        chatlist_id,
        signal
      )
      if (customerChatlist.data) {
        // Sort messages by updatedAt to ensure correct order
        let sortedMessages = customerChatlist.data.sort(
          (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
        )

        // Adjust messages to reverse consecutive 'user' messages
        sortedMessages = adjustUserOrder(sortedMessages)

        // console.log(sortedMessages)

        setMessages(sortedMessages)
      } else {
        setMessages([])
      }
    }

    const adjustUserOrder = messages => {
      return messages.reduce((acc, msg, index, arr) => {
        // Check if the current and previous messages are both 'user' and reverse order if true
        if (msg.role === 'user' && arr[index - 1]?.role === 'user') {
          // Reverse the last two elements in the accumulator
          const lastMessage = acc.pop()
          acc.push(msg, lastMessage)
        } else {
          acc.push(msg)
        }
        return acc
      }, [])
    }

    // Get the store_id from sessionStorage (assuming it exists)
    const storeId = sessionStorage.getItem('store_id')

    if (storeId) {
      GetConversationList(chatlistId, storeId)
    }

    // Listen for changes to chatlist_customer_id and reload conversation list if it changes
    const handleGetChatid = event => {
      if (event.detail) {
        if (chatlistId && storeId) {
          GetConversationList(chatlistId, storeId)
        }
      }
    }
    GetConversationList(chatlistId, storeId)

    window.addEventListener('loadchatlist', handleGetChatid)
    window.addEventListener('deletereload', handleGetChatid)

    window.addEventListener('chatlist_customer_id', handleGetChatid)

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('chatlist_customer_id', handleGetChatid)
      window.removeEventListener('loadchatlist', handleGetChatid)
      window.removeEventListener('deletereload', handleGetChatid)
      controller.abort() // Abort the fetch request if the component is unmounted or if the effect cleanup happens
    }
  }, [chatlistId]) // Dependency array includes chatlistId to reload when it changes

  useEffect(() => {
    if (messages.length > 0) {
      if (messages[0].role === 'assistant') {
        setMessages([...messages])
      }
    }
  }, [messages])

  const handleactivetrainbot = (userID, userMsg) => {
    if (activeuserId === userID) {
      console.log('same ID')
      SetactiveuserId('')
      SetactiveuserMessage('')
    } else {
      SetactiveuserId(userID)
      SetactiveuserMessage(userMsg)
    }
  }

  useEffect(() => {
    console.log(activeuserId, activeuserMessage)
  }, [activeuserId, activeuserMessage])

  const handleinsertform = async e => {
    e.preventDefault()

    // Access the input values
    const content = e.target.content.value

    // Wrap the data inside an array
    const data = [
      {
        title: activeuserMessage,
        type: 'unmatched',
        status: 'inactive',
        store_id: sessionStorage.getItem('store_id'),
        meta: {
          data: {
            question: activeuserMessage,
            answer: content
          }
        },
        training_processing: false
      }
    ]

    try {
      const res = await insertdataset(data)

      if (res.success && res.data[0].status === 'inactive') {
        const datasetid = res.data[0].dataset_id
        await new GetDataSet().setTrained([datasetid])
        SetshowpreviewMessage(false)
        SetactiveuserId('')
        SetactiveuserMessage('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <>
      {showpreviewMessage && (
        <div className='fixed inset-0 bg-black  p-5 bg-opacity-25 flex justify-center items-center z-50'>
          <div className='bg-[#FFFFFF] p-5 sm:p-7 shadow-lg w-full max-w-[900px] mx-auto my-[30px]   rounded-[24px] border-[1.6px] border-white overflow-y-auto min-h-[40%]  max-h-[90%] scrollbar-hide'>
            <div className='block'>
              <div className='flex justify-between'>
                <div className='mb-10'>
                  <h2 className='font-extrabold text-[20px] '>
                    Train Unanswered Question
                  </h2>
                  <h3 className='text-[16px] text-gray-600'>
                    Trained data will populated on Training &gt; Unanswered section
                  </h3>
                </div>
                {/* <button className="  text-black text-[13px] absolute right-9 font-semibold  rounded-[24px]" onClick={() => setformDialogopen(false)}>X</button> */}
                {/* <svg
                width='20'
                height='20'
                className='cursor-pointer'
                viewBox='0 0 30 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => {
                  setformDialogopen(false)
                  setupdateDialogopen(false)
                  setshowbuttons(true)
                }}
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M9.10041 7.77459C8.7343 7.40847 8.1407 7.40847 7.77459 7.77459C7.40847 8.1407 7.40847 8.7343 7.77459 9.10041L13.6742 15L7.77459 20.8996C7.40847 21.2657 7.40847 21.8593 7.77459 22.2254C8.1407 22.5915 8.7343 22.5915 9.10041 22.2254L15 16.3258L20.8996 22.2254C21.2657 22.5915 21.8593 22.5915 22.2254 22.2254C22.5915 21.8593 22.5915 21.2657 22.2254 20.8996L16.3258 15L22.2254 9.10041C22.5915 8.7343 22.5915 8.1407 22.2254 7.77459C21.8593 7.40847 21.2657 7.40847 20.8996 7.77459L15 13.6742L9.10041 7.77459Z'
                  fill='#373737'
                />
              </svg> */}
                <button
                  onClick={() => {
                    SetshowpreviewMessage(false)
                    SetactiveuserId('')
                    SetactiveuserMessage('')
                  }}
                  class='w-[30px]  sm:w-[40px] h-[30px] sm:h-[40px] text-[14px] sm:text-[18px] cursor-pointer'
                >
                  <svg
                    width='30'
                    height='30'
                    viewBox='0 0 30 30'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M9.10041 7.77459C8.7343 7.40847 8.1407 7.40847 7.77459 7.77459C7.40847 8.1407 7.40847 8.7343 7.77459 9.10041L13.6742 15L7.77459 20.8996C7.40847 21.2657 7.40847 21.8593 7.77459 22.2254C8.1407 22.5915 8.7343 22.5915 9.10041 22.2254L15 16.3258L20.8996 22.2254C21.2657 22.5915 21.8593 22.5915 22.2254 22.2254C22.5915 21.8593 22.5915 21.2657 22.2254 20.8996L16.3258 15L22.2254 9.10041C22.5915 8.7343 22.5915 8.1407 22.2254 7.77459C21.8593 7.40847 21.2657 7.40847 20.8996 7.77459L15 13.6742L9.10041 7.77459Z'
                      fill='#373737'
                    ></path>
                  </svg>
                </button>
              </div>
              <form onSubmit={handleinsertform}>
                <div className='w-full mt-2'>
                  <input
                    type='text'
                    name='usermessage'
                    className='bg-[#F8F7F7] focus:outline-none w-full h-[55px] rounded-[24px] p-5  '
                    placeholder='Question'
                    value={activeuserMessage} // Bind value to state
                    onChange={e => SetactiveuserMessage(e.target.value)} // Handle changes
                  />
                </div>
                <div className='w-full mt-3'>
                  {/* <input type="te" className="bg-[#F8F7F7] focus:outline-none w-full h-[180px] rounded-[24px] p-5  " placeholder="Title" /> */}
                  <textarea
                    name='content'
                    maxLength={2000}
                    cols='30'
                    rows='10'
                    placeholder='Answer'
                    className='bg-[#F8F7F7] focus:outline-none w-full h-[180px] rounded-[24px] p-5  '
                    // value={formData.content} // Bind value to state
                    required
                  ></textarea>
                </div>
                <small className='ml-2'>*Max.word limit 2000</small>
                <div className='flex justify-end mt-5'>
                  {/* <button className="w-[162px] border-[1px] border-black text-[14px] font-medium flex justify-center items-center p-2 mr-2 rounded-[19px]">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.5C12.4142 4.5 12.75 4.83579 12.75 5.25V11.25H18.75C19.1642 11.25 19.5 11.5858 19.5 12C19.5 12.4142 19.1642 12.75 18.75 12.75H12.75V18.75C12.75 19.1642 12.4142 19.5 12 19.5C11.5858 19.5 11.25 19.1642 11.25 18.75V12.75H5.25C4.83579 12.75 4.5 12.4142 4.5 12C4.5 11.5858 4.83579 11.25 5.25 11.25H11.25V5.25C11.25 4.83579 11.5858 4.5 12 4.5Z" fill="#252530" />
                                      </svg>

                                      Create Question
                                  </button> */}

                  <button
                    type='submit'
                    className='bg-[#7C50DD] text-white py-2 px-10 text-[14px]  rounded-[19px]'
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col items-center pt-5 pb-5 pl-9 pr-8 w-full overflow-x-hidden max-h-[320px] scrollbar-custom1 '>
        <div className='w-full max-w-2xl space-y-8 '>
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex ${
                msg.role === 'user' ? 'justify-end' : 'justify-start'
              }`}
            >
              <div className='flex space-x-4 items-start max-w-md'>
                {/* Avatar and message content */}
                {/* {msg.role === 'user' && (
                                <div className="w-9 h-8 rounded-full bg-[#bbbcbd] flex items-center justify-center">
                                </div>
                            )} */}

                {/* Message Content */}
                <div className='flex flex-col space-y-1 '>
                  {/* Timestamp and label */}
                  <div
                    className={`flex items-center text-xs text-gray-500 ${
                      msg.role === 'user' ? 'justify-end' : 'justify-between'
                    }`}
                  >
                    <span>
                      {DateTimeFormat(msg.updatedAt).time || 'Unknown Time'}
                    </span>
                    {/* {msg.role === 'assistant' && <span className="font-semibold mr-1">Shopify chatbot</span>} */}
                  </div>

                  {/* Message Bubble */}
                  <div className='flex flex-col items-start gap-2'>
                    <div
                      className={`px-4 py-4  w-[90%] ${
                        msg.role === 'user'
                          ? 'bg-[#9b6dff] text-[#ffffff] rounded-tl-lg rounded-bl-lg rounded-br-lg'
                          : 'bg-[#F2F2F2] text-[#768396] rounded-tr-lg rounded-br-lg rounded-bl-lg'
                      } text-[12px] leading-relaxed`}
                    >
                      {msg.content}
                    </div>
                    {msg.role === 'user' && (
                      <button
                        className={`px-2 py-2 flex gap-1 items-center justify-center hover:bg-[#7C50DD] hover:text-white border-[1px] rounded-[59px] 
                        ${
                          activeuserId === msg.message_id
                            ? 'bg-[#7C50DD] text-white'
                            : 'bg-white text-black'
                        }
                        `}
                        onClick={() => {
                          handleactivetrainbot(msg.message_id, msg.content)
                          SetshowpreviewMessage(true)
                        }}
                      >
                        <IonIcon
                          name='flash-outline'
                          className='text-[12px]  '
                        />
                        <p className='text-[10px] font-semibold'>Train Bot</p>
                      </button>
                    )}
                  </div>
                </div>

                {/* Bot Avatar (placed on the right for assistant role) */}
                {/* {msg.role === 'assistant' && (
                                <div className="w-9 h-8 rounded-full bg-[#bbbcbd] flex items-center justify-center">
                                </div>
                            )} */}
              </div>
            </div>
          ))}
        </div>
        <ToastContainer />
      </div>
    </>
  )
}

export default Conversation
